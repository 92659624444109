.find {
    padding: 30px;
    background: @off-gray;

    p {
        font-family: @font-family-base;
        .font-size(20);
        color: @light-grey-background-text-color;
        margin-bottom: 20px;
    }

    input {
        vertical-align: top;
        width: 260px;
        font-family: @font-family-base;
        text-transform: uppercase;
        height: 50px;
        border:0;
    }

    button {
        .dib;
        border: 0;
        color: @default-text-color;
        text-transform: uppercase;
        cursor: pointer;
        border: 1px solid @default-text-color;
        height: 50px;
    }
}

.template {
  display:none !important;
}

.map-key-header {
  color: @default-text-color;
  margin-top: 2rem;
}
.key-container {
  display: flex;
  margin-top: 2rem;
  border: 1px solid @footer;
  @media only screen and (max-width: @default-mobile-sm) and  (max-width: @default-mobile-lg) {
    display: block;
  }
  .key {
    flex: 1;
    display: table;
    padding: 1rem;
    max-width: 50%;
    @media only screen and (max-width: @default-mobile-sm) and  (max-width: @default-mobile-lg) {
      max-width: 100%;
    }
    &.premium-key {
      span {
        background: url(/images/c/theme/hudson_reed/ui/Icon-Location-pin-HR-664x951px.svg) 50% 50% no-repeat;
      }
    }
    p {
      display: table-cell;
      vertical-align: top;
    }
    span {
      background: url(/images/c/theme/hudson_reed/ui/Icon-Location-pin-664x951px.svg) 50% 50% no-repeat;
      height: 5rem;
      width: 4rem;
      margin-right: 1rem;
    }
  }
}

#or-search {
    padding: 20px;

    // p {
    //     font-family: @font-family-base;
    //     .font-size(18);
    // }

    #map-canvas {
        height: 500px;
        width: 100%;
    }
    .map {
        border: 1px solid @gray-base;
    }
}

@media only screen and (max-width: @default-mobile-lg) {
    .premium-intro {
        p {
            width: 100%!important;

        }

        .premium-logo {
            display: none!important;
            width: 50%!important;
            margin: auto!important;
            display: block!important;
        }
    }
}

@media only screen and (max-width: @default-mobile-sm) {
    .find {
        input {
            width: 60%;
        }

        button {
            width: 40%;
        }
    }
}

.showroom-title {
  font-weight: bold;
}