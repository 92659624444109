html{
	font-family: @font-family-base;
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

body {
	.font-size(12);
  color: #616161;
  font-weight: 300;
  font-family: @font-family-base;
}

h1 {
  .font-size(60);
  @media only screen and (max-width: @default-mobile-sm) {
    .font-size(40);
  }
	color: @default-text-color;
	margin-bottom: 20px;	
	font-weight: 400;
	padding: 5px 0;
	font-family: @font-family-h1;
	text-transform: uppercase;
}

h2 {
	color: @default-text-color;
	display: block;
	.font-size(30);
	//font-weight: 300;
	font-weight:400;
	font-family: @font-family-h2;
	line-height: 29px;
  margin-bottom: 10px;
}

h3 {
	font-family: @font-family-h3;
	.font-size(24);
	//font-weight: 300;
	font-weight:400;
	line-height: 27px;
	margin-bottom: 10px;
  color: @white;
  &.alternate {
    color: @default-text-color !important;
  }
}

h4 {
	font-family: @font-family-titles;
	.font-size(18);
	margin-bottom: 20px;
	//font-weight: 300;
	font-weight:400;
}

p {
	margin-bottom: 10px;
	line-height:28px;
	.font-size(12);
	font-weight:300;
	color: @default-text-color;
	font-family: @font-family-base;

	&:last-child {
		margin-bottom: 0;
	}
}
span {
	.font-size(12);
	display: block;
  font-family: @font-family-base;
  font-weight:300;
  color: @default-text-color;
}
	
a {
	display: block;
	text-decoration: none;
	font-weight: 300;
	.font-size(12);
  font-family: @font-family-base;
  color: @default-text-color;
}

ul {
	font-size: 0;
}

ul li,
ol li{
	.font-size(16);
	line-height: 28px;
	color: @default-text-color;
	font-family: @font-family-base;
}

ul li a,
button {
	.font-size(16);
	color: @default-text-color;
	font-family: @font-family-base;
}

i {
	.font-size(14);
}

small {
	.font-size(12);
}

a:hover {
	text-decoration: underline;
}

strong {
	font-weight: 600;
}

@media only screen and (max-width: @default-tablet-lg) {
	p {
		.font-size(12);
	}
}

@media only screen and (max-width: @default-mobile-lg) {
	h2 {
		.font-size(24);
		line-height: 21px;
	}

	h3 {
		.font-size(22);
	}

	p {
		.font-size(14)!important;
	}
}

@media only screen and (max-width: @default-tablet-sm) {
	small {
		.font-size(10)
	}
}

@media only screen and (max-width: @default-mobile-sm) {
	ul li,
	ul li a {
		.font-size(14);
	}
}