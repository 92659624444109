#about-us {
	padding: 0 (@page-padding * 2);
	padding-bottom: 20px;
    h1 {
        margin: 20px 0;
    }
    h2 {
      color: @default-text-color;
    }

    .intro {
        color: @default-text-color;
		padding: 0;
    }

    p {
    	.font-size(12);
    	line-height: 19px;

    }

    .about-us-section {
      // &:not(:last-of-type){
      //   border-bottom: 1px solid #dfdfdf;
      // }
      padding-bottom: 2rem;
      // &:not(:first-of-type) {
      //   padding-top: 2rem;
      // }
      
    }

    .contact-us {
    	background: #e3e8e9;
    }
}
