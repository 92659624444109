#error-page{
    
    .error-page-content{
        padding: 0; 
        margin-bottom: 30px;    
        
        >h1{
            //color:@hr-red;
            color: @default-text-color;
        }
        a{
            color: @default-text-color;;
            &:hover{
                color:#000;
                text-decoration:none;
            }
        }
        >p{
            background-color: initial;
            padding: 0;            
            a{ 
                display: inline-block;
                text-decoration: underline; 
            } 
        }
        
        >ul{            
            li{  
                display: inline-block;
                border-bottom: 1px solid #4a4c4c;
                //background: url('@{img-path}/maroon-arrow.png') 99% 50% no-repeat;
                width: 96% /3 ;              
                a{
                    padding: 10px;
                }
                &:not(:nth-child(3n+3)){
                    margin-right: 2%;
                }      
                &:nth-child( -n+3 ){
                    border-top: 1px solid #4a4c4c; 
                }
                      
            }
            
        }
    }
    
    .sellers .box a{
        height: 0;
    }
    
}

@media only screen and (max-width: @default-mobile-lg) {

    #error-page .error-page-content{
        
        >ul li{
            margin: 0;
            width: 100%; 
            
            &:nth-child( -n+3 ){
                border-top: 0;  
            } 
        
            &:first-child{
                border-top: 1px solid #4a4c4c !important;  
            }
            
        }
        

        
    }
}