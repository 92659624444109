#search-results {
    padding: 20px;

    h1 {
        color: @default-text-color;
        .font-size(30);
    }
    span {
        .dib;
    }

    

    dl {
        border-top: 1px solid lighten(@gray-base, 40%);
        border-bottom: 1px solid lighten(@gray-base, 40%);
        height: 40px;
        .font-size(0);

        dt {
            .dib;
            .font-size(12);
            line-height: 25px; 
            margin: 7px;
        }

        dd {
            .dib;
            margin: 7px;
            line-height: 23px;
            height: 25px;
            width: 25px; 
            text-align: center;

            a {
                text-decoration: none;
                display:block;
                color: @default-text-color;
            }

            &:hover {
                background: lighten(@hr-red, 30%);
            }
        }

        span {
            background: @default-text-color;
            display: block;
            text-align: center;
            color: @white;
        }
    }

    .items {
        overflow: hidden;
        width: 100%;
        padding: 20px 0;
    }
}