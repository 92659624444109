.breadcrumb{
	max-width:100%;
	background:#fff;
  margin:0 0 2rem 0;
  padding-top: 102px;
	ol{
		max-width: 1024px;
		display: block;
		margin: 0 auto;
		height: inherit;
		padding:1rem 32px;
		li{
			.dib;
			&:first-child{
				a{
					font-weight:700;
				}
      }
      &.separator {
        margin: 0 .5rem;
      }		
			a, span{
				.font-size(12);
				color:#777;
				display:inline-block;
      }	
		}
	}
}