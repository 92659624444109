.hidden {
    display: none;
}
.is-hidden .header {
    display: block;
    background-color: @white;
}
.mega {
    text-align: left;
    font-size: 1em;
    padding-bottom: 20px;
    //background: url("/images/c/theme/hudson_reed/ui/shadow-divider.jpg") 0 100% repeat-x;
    .header {
        width: 80%;
        margin-bottom: 30px;
        h2 {
            .font-size(28);
            color: @brand-base;
            line-height: 32px;
        }
        p {
          color: @default-text-color;
            line-height: 23px;
            .font-size(12);
        }
    }
    .products-tag-line {
        color: @default-text-color;
        .font-size(22);
        margin-bottom: 20px;
        line-height: 29px;
    }
    h3 {
        margin: 2rem 0;
        padding: 2rem 0;
        font-family: @font-family-titles;
        text-align:center;
        color:@default-text-color;
        //.font-size(24);
    }
    .level.box {
        width: 32%;
        padding: 0;
        margin-right: 2%;
        margin-bottom: 20px;
        &:nth-of-type(3n+3) {
            margin-right: 0;
        }
        .teaser {
            position: relative;
            h3,h4{
                text-transform:uppercase;
            }
        }
        &:hover {
          span {
            color: @default-text-color;
            text-decoration: underline;
          }
        }
    }
}

@media only screen and (max-width: @default-tablet-lg) {
    .mega .header {
        width: 100%;
    }
}   

@media only screen and (max-width: @default-mobile-lg) {
    .mega {
        .products .box {
            width: 100%;
            display: block;
        }
    }
}

@media only screen and (max-width: @default-mobile-sm) {
    .mega .level .teaser {
        height: 64px;
    }
    .mega > section {
        width: 100%;
        display: block;
        padding: 0 15%;
    }  
}