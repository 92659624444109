.static.content-intro .intro.with-image {
	p {
		width: 75%;
		.dib;
		vertical-align: top;
	}

	img {
		width: 25%;
		border: 1px solid lighten(@gray-base, 10%)
	}
}

@media only screen and (max-width: @default-tablet-sm) {
	.static.content-intro .intro.with-image {
		img {
			display: block;
			margin: auto;
			width: 50%;
			margin-bottom: 20px;
		}

		p {
			padding-left: 0;
			width: 100%;
		}
	}
}