.terms {
	padding: 0 (@page-padding * 2) 20px (@page-padding * 2);
	p {
		.font-size(12);
		a, span {
			.dib;
			.font-size(12);
			line-height: 19px;
		}

		a {
			color: @brand-base;
		}
	}

	ul {
		padding-left: 20px;
		li {
			.font-size(12);
			list-style-type: disc;
			line-height: 19px; 
			color: @default-text-color;
		}
	}
}