.cookie-policy{
    background:@hr-black;
    color:#fff;
    position: fixed;
    bottom:0;
    z-index: 2;
    width:100%;
    &.hidden{
        display:none;
    }
    >div{
        padding: 1.5rem 32px;
        p{
            display:inline;
            color:#fff;
            font-weight:700;            
            margin:0;
            span{
                display:inline-block;
                .font-size(13);
            }
            .learn-more{
                display:inline-block;
                color:#fff;
                text-decoration:underline;
                .font-size(13);
            }
        }
        .continue{
            background: #fff;
            padding: 1rem;
            border-radius: 5px;
            float: right;
            color:#000;
            .font-size(15);
        }
    }
}