.lvl-tabbed {
    position: relative;
    bottom: 97px;
    .tabs {
        text-align: left;
    }
    span {
      color: #fff;
    }
    .active {
      a {
        color: @default-text-color;
      }
      span {
        color: @default-text-color;
      }
    }
}
.level-page .content {
    margin-bottom: -97px;
}
.top {
    position: relative;
    margin-bottom: 35px;
    overflow:hidden;
    position: relative;
    text-align: center;
    img {
        width: 100%;
    }
    .insider {
        display: none;
    }
}
.level-container {
    width: 93%;
    margin: auto;
}
.children {
  height: 505px;
  overflow:hidden;
  position: relative;
}
.children.levels {
    height: auto;
    margin-bottom: 62px;
    overflow: visible;
}
.children.expanded {
    height: 100%!important;
}
.children-wrapper {
  overflow: hidden;
  height: inherit;
  position: relative;
  margin: 0 40px;
}
.all-products {
    display: none;
}
.animate {
  .transition(@timing: 0.25s);
}
.previous-page,
.next-page {
    position: absolute;
    z-index: 100;
    height: 36px;
    width: 36px;
    text-indent: -200px;
    top: 50%;
    margin-top:-1.8rem;
    overflow: hidden;
    cursor: pointer;
    background-repeat: no-repeat;    
    padding:0;
    a {
        display: none;
    }
}
.children.products {
    .previous-page,
    .next-page{
        top: 46%;
    }
}
.previous-page{
    left: 0;
    background-image: url("@{img-path}/HR-Web-Arrow-Big-Left.svg");
    //background-position: 10px 50%;
    &:hover{
        background-image: url("@{img-path}/HR-Web-Arrow-Big-Left-Hover.svg");
    }
}
.next-page {
    right: 0;
    background-image: url("@{img-path}/HR-Web-Arrow-Big-Right.svg");
    //background-position: -27px 50%;
    &:hover{
        background-image: url("@{img-path}/HR-Web-Arrow-Big-Right-Hover.svg");
    }
}
.page {
    overflow: hidden;
    position: relative;
    width: 100%;
}
.expanded .page {
    position: relative;
    display: flex;
}
.area {
    height: inherit;
    position: relative; 
    width: 100%;
    overflow: hidden;
}
.expanded .area {
    position: relative;
}
.filters {
    margin: 20px 50px;
    overflow: hidden;
     ul {
        list-style-type: none;
        margin: 0 0 5px 0;
        padding: 0;
        overflow: hidden;
        text-align: center;
    }
}
.filters.filters li {
  display: inline-block;
  margin: 7px 5px 0 5px;
  .font-size(14);
    &:first-child {
        display: block;
    }
}
.filters .cta {
    line-height: 1em!important;
    border-radius: 20px!important;
    padding: 5px 10px!important;
    margin:0!important;
    background-color: #e8e7e6;
    //color: @hr-red!important;
    color: @default-text-color;
    height: auto!important;
    text-transform: capitalize!important;
    border: solid 1px #d1d0ce;
    .font-size(12);
    cursor: pointer;
    &:hover {
        background-color: @white;
    }
}
.filters li.active .cta {
    color: #FFF;
    border: solid 1px #d1d0ce;
    background: @white;
    span {
      color: @default-text-color;
        .font-size(12);
    }
}
.filters .all.active .button {
  cursor: default;
}
.filters ul {
    display: none;
}
.filters.expanded ul {
    display: block;
}
.apply-filters button:focus {
    outline: none;
}
.apply-filters {
    padding-bottom:10px;
    font-size: 1.2em;
    border-bottom: 1px solid #b2b1ad;
    cursor: pointer;
    a {
      color: @default-text-color;
      display: inline-block;
      text-transform: uppercase;
      @media only screen and (max-width: @default-mobile-sm) {
        width: 100%;
      }
    }
}
.filters.expanded {
  .filter {
    li {
      margin-right: 2rem;
      &.active {
        a {
          text-decoration: none;
          background-color:  @button-color;
          color: #ffffff;
          span {
           color: #ffffff;
          }
        }
      }
      a {
        cursor: pointer;
        text-transform: uppercase;
        @media only screen and (max-width: @default-mobile-sm) {
          width: 100%;
        }
      }
    }
  }
  .apply-filters {
      margin-bottom: 20px;
  }
}
.filters .expand {
    float: right;
    height: 11px;
    width: 11px;
    background: url("@{img-path}/plus-minus.png") 0 0 no-repeat;
    cursor: pointer;
    border: none;
    padding: 0;
    margin-top: 6px;
}
.filters.expanded .expand {
    background-position: 0 -11px;
}
.filters a {
    text-decoration:none;
}
.page-container{ 
    overflow: hidden;
    position: absolute;
    bottom: -62px;
    height: 62px;
    width: 100%;
    text-align: center;
}
.pages {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    height: 18px;
    .dib;
    li {
        float:left;
        height: 18px;
        width: 18px;
        text-indent: -100px;
        overflow: hidden;
        background: #fff;
        border: 1px solid #888;
        margin: 0 3px;
        border-radius:100%;
        cursor:pointer;
    }
    li.button a {
        display: none;
    }
    li.active {
        background: @hr-red;
    }
}
.pages.hide,
.view-all.hide,
.expanded .pages,
.expanded .previous-page,
.expanded .next-page {
    display:none !important;
}
.view-all {
    padding: 15px 0;
    a{
        .font-size(14);
        color: @default-text-color;
        cursor:pointer;
        text-decoration:none;
        display:inline-block;
    }
}
.eco {
    padding: 20px 0;
    img {
        float: left;
        margin-right: 20px;
    }
}
.shower-type {
    padding: 5px 0;
    img {
        float: left;
        margin-right: 20px;
    }
}
@media only screen and (max-width: @default-tablet-lg) {
    // .children.levels {
    //     height: 801px;
    // }
    .lvl-tabbed {
        bottom: 20px;
    }
    .tabs{
        vertical-align: center;
        a{
            border: 1px solid @hr-red;
        }
        .active a{
            padding: 10px;
        }
    }
    .level-page .content {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: @default-mobile-lg) {
    .product.box,
    .level.box {
        width: 50%;
    }
    // .children.levels {
    //     height: 1101px;
    // }
}

@media only screen and (max-width: @default-mobile-sm) {
    .product.box,
    .level.box {
        width: 100%;
    }
    .children-wrapper {
        overflow: auto
    }
    .view-all {
        display: none;
    }
    // .children.levels {
    //     height: 700px;
    // }
    .tabs .active a {
        background: none;
    }
    .lvl-tabbed {
        .tabs{ 
            text-align: center;   
        }
    }
}
