//== Colors
//
//## Brand colors.

@bg-body:                #e2e2e2;
@off-gray: 			     #F2F2F2;

@gray-base:              #797979;
@gray-lighter:           #717170;
@gray-darker:            #4a4c4c;

@footer-bg-1:     #2f2f2f;
@footer-bg-2:     #242424;
@footer-bg-3:     #1e1e1e;

//@brand-base:             #43235d;
@brand-base: 			 #888;
@brand-secondary:        #E7E3EA;
@brand-third:            #c3b8cb;
@brand-forth:            #D4CCD7;
@brand-bg:               #a090ad;
@brand-danger:           #8A6A8A;
@brand-blue:             #2ab1e2;

//@hr-base-text:           #b2b1ad;
@hr-base-text:           #4a4c4c;
//@hr-red:                 #a1224d;
@hr-red:				#888; //Obviously variable name requires change
@hr-black:               #000000;
@white:  				 #ffffff;
@hr-highlight:				#74A3AD;

@wishlist:			 	 #fae194;
@wishlist-hover:		 darken(@wishlist, 13.5%);

@regular-text-colour:	#666;
@link-hover-colour:		#000;

//March 2018 Redesign
@header-dark-grey: #333333;
@header-light-grey: #f0f0f0;
@footer-pod: #a3a3a3;
@footer: #575756;
@mega-menu: #dedede;
@button-color: #a3a3a3;
@mega-menu-text: #333333;
@footer-text: #f0f0f0;
@header-top-text: #f0f0f0;
@light-grey-background-text-color: #575756;
@default-text-color: #575756;
@inspire-me-pod: #818285;

//== Typo
//
//## Font.

//@font-family-titles:  'Raleway', sans-serif;
@font-family-titles:Pistilli;
@font-family-h1: Pistilli;
@font-family-h3: Montserrat;
@font-family-h2: Montserrat;
@font-family-titles-orig:  'Raleway', sans-serif;

/* @font-face{
	font-family:montserrat;
	src:url('https://fonts.google.com/specimen/Montserrat?selection.family=Montserrat');
} */
@font-family-base:'Montserrat';
//@font-family-base:    Arial, Helvetica, sans-serif;


//== Container sizes
//
//##

	// Default Media Queries
	@default-tablet-lg:         768px;
	@default-tablet-sm:         680px;
	@default-mobile-lg:         580px;
	@default-mobile-sm:         480px;

	// Page container & padding.
	@page-width:			  1024px;	// Set page width
	@wrapper-padding:		  30px;		// Set page padding
	@page-padding:			  100% * (@wrapper-padding / (@page-width - @wrapper-padding * 2)) / 2;
	@page-inner:			  @page-width - (@wrapper-padding * 2);

	// Grid system
	@columns: 18;

	@gutter-setter-1:        20px; // 1.554756986300518
	@gutter-1:               100% * (@gutter-setter-1 / @page-inner); // Worth 20px to the full page

	@gutter-setter-2:        10px;
	@gutter-10px:            100% * (@gutter-setter-2 / @page-inner); // Worth 10px to the full page

	@col-1-3: 		       (100% - (@gutter-10px * 2 )) / 3;
	@col-1-2: 		       (100% - (@gutter-10px * 2 )) / 2;
    
	@col-3-18:             (100% - (@gutter-1 * 2 )) / @columns * 3;
	@col-4-18:             (100% - (@gutter-1 * 2 )) / @columns * 4;
	@col-5-18:             (100% - (@gutter-1 * 2 )) / @columns * 5;
	@col-6-18:             (100% - (@gutter-1 * 2 )) / @columns * 6;
	@col-7-18:             (100% - (@gutter-1 * 2 )) / @columns * 7;
	@col-8-18:             (100% - (@gutter-1 * 2 )) / @columns * 8;


	@twenty-lg-tab: 		100% * (20 / @default-tablet-lg);
    
   

//
//## IMG Path
	@img-path:         '/images/c/theme/hudson_reed/ui';
	@img-path-1:       '/images/c/theme/hudson_reed';
