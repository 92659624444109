html {
	min-height: 100%;
}
body{
	position: relative;
    margin: 0;
    padding: 0;
     -webkit-print-color-adjust: exact;
}
@font-face {
    font-family: Pistilli;
    src: url("/fonts/Pistilli.otf") format("opentype");
}
.page-header > div,
nav,
footer,
.footer-inner,
.wrapper,
.extra-content,
.banner-wrap {
	max-width: @page-width;
	display: block;
	margin:0 auto;
	font-size: 0;
	height: inherit;
	padding: 0 32px;
}
.banner-wrap {
	padding: 0;
}
footer {
	min-width: 100%;
	padding: 0;
}
.content-area {
    padding: @page-padding * 2;
    p {
    	color: @default-text-color;
    	font-family: @font-family-base;
    }
}
.content-intro {
	padding: @page-padding;
}
.video-guides {
	.content-area {
		border-bottom: 1px solid #d2dade;
	}
}
.faqs {
	.intro {
		padding: 0;
		p, a {
			.font-size(14);
			color: @default-text-color;
			font-family: @font-family-base;
		}
		a {
			text-decoration: underline;
		}
	}
}
.faqs.sub-content {
	border: 1px solid @gray-base;
	padding: 40px 30px;
	margin: 20px;
	p {
		color: @default-text-color;
		line-height: 20px;
		.font-size(12);
	}
}
.intro.with-image {
	h2 {
		.font-size(24);
		color: @default-text-color;
	}
	img {
		.dib;
		vertical-align: top;
		width: 22%;
	}
	.txt-red {
		color: @default-text-color;
		.font-size(14);
		line-height: 23px;
		font-family: @font-family-base;
		padding-left: 5%;
	}
	.intro-wrap {
		.dib;
		width: 78%;
		padding-left: 30px;
	}
}
.intro-text {
	color: @default-text-color!important;
	line-height: 23px;
	.font-size(14)!important;
	font-family: @font-family-base;
}
.lhset {
	line-height: 19px;
	.font-size(12);
}
// Grid System
.col-1-3 {
	width: @col-1-3;
	.dib;
}
.col-1-2 {
	width: @col-1-2;
	.dib;
}
.col-3-18 {
	width: @col-3-18;
	.dib;
}
.col-4-18 {
	width: @col-4-18;
	.dib;
}
.col-5-18 {
	width: @col-5-18;
	.dib;
}
.col-6-18 {
	width: @col-6-18;
	.dib;
}
.col-7-18 {
	width: @col-7-18;
	.dib;
}
.col-8-18 {
	width: @col-8-18;
	.dib;
}
.gutter-3 {
	margin-right: @gutter-1!important;
}
.gutter-10px {
	margin-right: @gutter-10px!important;
}
.gutter-r-30px {
	margin-right: @gutter-10px * 3!important;
}
.gutter-b-30px {
	margin-bottom: @gutter-10px * 3!important;
}
//  Helper
.inner-pad {
	padding-left: @page-padding;
}
.loading-overlay{
	height: 100%;
    width: 100%;
	background: rgba(0,0,0,0.6) url("@{img-path}/loading.gif") 50% 45% no-repeat;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
}
@media only screen and (max-width: @default-tablet-lg) {
	.page-header{
		>div{
			padding:0 1.5%;
		}
	}
	nav,
	.extra-content {
		padding: 0;
	}
	.wrapper {
		padding: 0 @twenty-lg-tab;
	}
}

@media only screen and (max-width: @default-mobile-sm) {
	.intro.with-image {
		img {
			display: none;
		}
		.intro-wrap {
			width: 100%;
		}
	}
}


.fa:before {
  color: #000;
  position: absolute;
  font-size: 2rem;
}