.slider-container {
    z-index: -1;
    min-width: 100%;
    padding: 0;
    overflow: hidden;
    margin-top:0;
    max-height:100vh;
}
.home {
   max-width:100%;
   padding:0;
}
.carousel{
    position: relative;
    overflow: hidden;
    height:100%;
    max-height:100vh;
    &.loading {
      position: relative;
    }
    .carousel-item-list{
        &.loading {
          visibility: hidden;
        }
        .carousel-item{
            position:relative;
            float:left;
            width: 100%;
            max-height:100vh;
            background:#000;
            img{
                position: relative;
                min-width:100vw;
                height:100vh;
               left:50%;
               transform:translateX(-50%);
               opacity:.9;
            }
            .slide-content-layer{
                width:100vw;
                position: absolute;
                bottom:31%;
                .slide-content{
                    max-width: 1024px;
                    display: block;
                    margin: 0 10.7%;
                    @media only screen and (max-width: @default-mobile-sm) and  (max-width: @default-mobile-lg){
                      margin: 0 auto;
                    }
                    @media only screen and (min-width: 1024px) and (max-width: 1366px){
                      margin: 0 15%;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1023px){
                      margin: 0 13%;
                    }
                    height: inherit;
                    padding: 0 32px;
                    span{
                        .font-size(18);
                        color:#fff;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        text-decoration: underline;
                        margin-bottom: 1rem;
                    }
                    p {
                      .font-size(18);
                      color:#fff;
                      letter-spacing: 2px;
                      max-width: 447px;        
                    }
                    h1{
                        max-width: 80%;
                        color:#fff;
                        line-height:1;
                        text-transform: uppercase;
                    }
                    a{
                        .font-size(18);
                        color:#fff;
                        letter-spacing:2px;
                        text-transform: uppercase;
                        border-bottom:1px solid;
                        padding-bottom:3px;
                        display:inline-block;
                        &:hover{
                            text-decoration:none;
                        }
                    }
                }
            }
            .animation-fading&{ 
                top:0;
                left:0;
            }
        }
        .animation-fading{
            overflow: hidden;
        }
    }
    .slide-image-link:hover {
        text-decoration: none;
    }
    .carousel-progress-bar{
        width:100%;
        height: 5px;
        background: #fff;
        bottom:0;
        position: absolute;
        >div{
            width:0;
            background: #74A2AD;
            display:inline-block;
            height:100%;
            transition:width 6s linear;
        }
    }
}
.carousel-additionals {
    position: relative;
}
.carousel-item-markers{
    position: absolute;
    bottom:22%;
    width:100vw;
    ol{
        width:100%;
        max-width: 1024px;
        display: block;
        margin: 0 10.7%;
        @media only screen and (max-width: @default-mobile-sm) and  (max-width: @default-mobile-lg){
          margin: 0 auto;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1366px){
          margin: 0 15%;
        }
        @media only screen and (min-width: 768px) and (max-width: 1023px){
          margin: 0 13%;
        }
        padding: 0 32px;
        .item-marker{
            display: inline-block;
            margin: 4px;
            border: 0;
            cursor: pointer;
            .font-size(10);
            span{
                //font-family:Pistilli;
                .font-size(10);
                color:#fff;
                display:inline;
                &:before {
                  color: #fff;
                }
            }
            &:not(:nth-last-child(2)){              
                // &:after{
                //     content:"/";
                //     .font-size(30);
                //     color:#fff;
                //     margin:0 0 0 1rem;
                // }
            }
            &:first-child {
                margin-left: 0;
            }
            &.active{
                span{
                    color:#74A2AD;
                }
            }
        }
    }
}

.carousel-insider {
    height: 26px;
    margin-bottom: 20px;    
    .insider {
        display: none;
        float: right;
        font-size: 0;
        h2 {
            .dib;
            vertical-align: top;
            background-color: @white;
            margin: 0;
            .font-size(14);
            line-height: 26px;
            padding: 0 30px;
            vertical-align: top;
        }
    }
    .insider.active {
        .dib;
    }
}

// Keyframe animation on slider
.carousel-insider .insider {
    animation: fader 5200ms infinite linear 1000ms;
    opacity: 0;
}

@keyframes fader {
    0%,   5%     { opacity: 0; }  
    10%,  90%    { opacity: 1; }  
    95%,  100%   { opacity: 0; }
}

@media screen and (min-aspect-ratio: 32/17){
    .carousel{
        .carousel-item-list{
            .carousel-item{  
/*                 img{
                    height:auto;
                } */
            }
        }
    }
}

@media only screen and (max-width: @default-tablet-lg) {
    .slider-container {
        //max-height:90vh;
        max-height:70vh;
        margin-top:0;
    }
    .carousel{
        max-height:70vh;
        .carousel-item-list{
            .carousel-item{
                max-height:70vh;                
                img{
                    width:auto;
                    margin-top:-12rem;
                }
                .slide-content-layer{
                    bottom: 16vh;
                    .slide-content{
                        h1{
                            .font-size(42);
                            max-width:100%;
                        }
                    }
                }
            }
        }
        .carousel-item-markers{
            bottom:10vh;
        }
    }
}

@media only screen and (max-width: @default-mobile-sm) {
    .carousel{
        .carousel-item-list{
            .carousel-item{
                img{
                    width:100vw;                 
                }
            }
        }
    }
}