#results {
	padding: 30px 0;

    h2 {
        border-bottom: 1px solid lighten(@gray-base, 40%);
        padding-bottom: 10px;
        margin-bottom: 30px;
        padding-top: 20px;
        font-weight: 600;
    }
}

#premium {
	padding-bottom: 20px;
}

.premium-intro {
	padding: 0 (@page-padding * 2);
	p {
		font-family:@font-family-base;
		color: @default-text-color;
		line-height: 23px;
		.font-size(16);
		width: 75%;
		.dib;
	}

	.premium-logo {
		width: 20%;
		.dib;
		margin-left: 5%;
	}
}

.premium-header {
	border-top: 1px solid #cdd6da;
	padding-top: 30px;

}

.store.premium {
	.map {
		height: 380px;
		width: 50%;
		.dib;
	}
	.store-info {
		width: 50%;
		.dib;
		padding-right: 20px;
	}
}

#stockists .store {
	.map {
		width: 50%;
    	height: 180px;
		.dib;
	}
	.store-info {
		width: 50%;
		.dib;
		padding-left: 20px;
	}
}

.contact {
	li a {
		color: @default-text-color!important;
	}
}

.store {
	min-height: 180px;
    border-bottom: 1px solid #cdd6da;
    padding-bottom: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
}

.store-info {

	.store-logo {
		margin-bottom: 15px;

		.premium-logo {
			margin-left: 5px;
			vertical-align: top;
			float: right;
		}
	}

	h3 {
		.font-size(16);
	    margin-bottom: 10px;
	    color: @default-text-color;
	}

	ul {
	    list-style-type: none;
	    margin-bottom: 10px;
	    padding: 0;
	    font-weight: 400;

	    a {
	    	color: @default-text-color;
	    }

	    li, a {
	    	.font-size(12);
	    	line-height: 22px;
	    	color: @default-text-color;
	    }
	}

	.store-desc p {
		line-height: 19px;
		margin: 0;
		.font-size(12);
	}
}

@media only screen and (max-width: @default-tablet-sm) {
	#premium, #stockists {
		.store.premium, .store > div {
			display: block;
			width: 100%;
			padding: 0;
		}

		.store-info {
			margin-bottom: 10px;
		}
	}
}