.do-you-need {
    margin-bottom: 20px;
    .top {
        border: 0;

        h3 {
          color: @default-text-color;
            .dib;
            padding-bottom: 20px;

        }

        p {
            .dib;
            color: @default-text-color;
            margin: 0;
            vertical-align: top;
            .font-size(12);
        }
    }
}
