header.page-header {
  &.mobile {
    display:none;
    background: @header-light-grey;
  }
	background: @header-light-grey;
	position: relative;
  z-index: 11;
  width: 100%;
  &.sticky {
    position: fixed;
    top: 0;
  }
  @media only screen and (max-width: @default-mobile-sm) {
    &.mobile + .breadcrumb {
      padding-top: 0;
    }
  }
  &>div {
    max-width: 100%;
  }
	nav{
		background:none;
		ul{
			li{
				width:auto;
				a{
					color:@light-grey-background-text-color;
					text-transform:uppercase;
					cursor:pointer;
          display:inline-block;
          span {
            color: @light-grey-background-text-color;
          }
          .trade-customer {
            border: 1px solid #666;
            padding: 0 6px;
          }
					&:hover{
            box-shadow:none;
            color:@light-grey-background-text-color;
            text-decoration: underline;			
					}
				}
			}
		}
	}	
	>nav{
		text-align:right;
		margin:0 auto;
		ul{
			li{
				display:inline-block;
				text-align:right;
				padding:0;
        margin:0 0 0 5%;
        a {
          span{
            .font-size(12);
          }
        }
			}
		}
	}
	.top-header-bar{
		max-width:100%;
		background: @header-dark-grey;
		padding:0;
		>ul{
			max-width: 93%;
			display: block;
			margin: 0 auto;
			height: inherit;
			padding:0 32px;
			li{
        a {
          color: @header-top-text;
          span{
            color: @header-top-text;
          }
          &:hover {
            color: @header-top-text;
            span {
              color: @header-top-text;
            }
          }
        }
				&:first-child{
					margin:0;
				}
			}
		}
	}
	#logo{
		display:inline-block;
    //width:57%;
    width: 42%;
		vertical-align:middle;
		a{
			display:inline-block;
			img{
				max-width:100%;
			}
		}
  }
	.header-right{
		display:inline-block;
		padding:0;
    //width:43%;
    width: 58%;
		text-align:right;
		vertical-align:middle;
    margin:1rem 0;
    max-width: 93%;
		>ul{
			overflow: visible;
			display:flex;
			justify-content:space-between;
			>li{				
				position:static;
        flex:1;
        text-align: left;
        display: inline-block; // Flex fallback
        min-width: 20%; // Flex fallback
        &.blog {
          flex: 0.5;
          min-width: 15%; // Flex fallback
        }
        &.search {
          flex: 1.9;
          position: relative;
          min-width: 34%; // Flex fallback
          button {
            background-color: transparent;
            border: none;
            width: 10%;
            height: 100%;
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            &:before {
              position: absolute;
              top: 10px;
              right: 7px;
            }
          }
          input {
            width: 100%;
            height: 97%;
            padding: 1rem;
            border: 1px solid @header-light-grey;
            apperance: none;
            border-radius: 0;
          }
        }
        &.header-wishlist {
          flex: .5;
          position: relative;
          min-width: 10%; // Flex fallback
          a{
            display: block;
            width: 100%;
            height: 100%;
            z-index: 100000000;
            span {
              position: absolute;
              right: 38%;
              top: 23px;
              font-size: 1rem;
            }
          }
          &:before {
            position: absolute;
            top: 10px;
            right: 40%;
            z-index: -10;
          }
        }
        &.header-wishlist:before, &.search button:before {
          color: @light-grey-background-text-color;
        }
				a{
					span{
						.font-size(17);
					}
				}
				.search-toggle{
					width:3rem;
					height: 3rem;
					background:url('@{img-path}/search.svg') no-repeat;
					border:0;
					outline:0;
					vertical-align: bottom;	
					cursor:pointer;			
					&.search-active{
						background:url('@{img-path}/close.svg') no-repeat;
					}
				}				
			}
		}

		//testing
		.mobile-nav{
			display:none;
			width:5rem;			
			vertical-align:top;
			position:static;
			.burger{
				padding:1rem;
				height:5rem;
				cursor:pointer;
				 span{
					width:3rem;
					height:2px;
					margin-bottom:9px;
					background-color:@default-text-color;
					transition:all .3s;
					opacity: 1;
				}
				&.open{
					span{
						&:first-child{
							transform:rotate(45deg) translate3d(6px,9px,0);
						}
						&:last-child{
							transform:rotate(-45deg) translate3d(-1px,-2px,0);
						}
						&:nth-child(2){
							display:none;
						}
					}
					& + .mobile-menu{
						display:block;
					}
				}
			}
			.mobile-menu{
				display:none;
				position:absolute;
				width:100vw;
				max-width:100%;
				box-sizing:border-box;
				left:0;
				background:@header-light-grey;
				top:11rem;
				li{
					width:100%;
					text-align: left;
					padding: 0 2rem;
					border-bottom: 1px solid #999;
					&:first-child{
						border-top:1px solid #999;
					}
					a{
						display:block;						
						padding:1rem 0;
					}
				}
			}
		}
	}
	.dropdown{
		max-width:100%;
		position: absolute;
		width:100%;
		left: 0;
		top:100%;
		background-color:@mega-menu;
		z-index:10;
		text-align:left;
    padding:0 0 2rem 0;
    h3 {
      color: @mega-menu-text;
    }
		>div{
			max-width:1024px;
			margin:auto;
    }
    .mega-menu {
      display: flex;
      max-width: 1024px;
      margin: 4rem auto;
      font-size: 0;
      padding: 0 32px;
      justify-content: space-between;
      border-spacing: 2rem; // Flex fallback
      .header-block {
        max-width: 26%;
        width: 26%; // Flex fallback
        display: table-cell; // Flex fallback
          nav {
            background: 0 0;
            flex: 1 1 100%;
            padding: 0;
            &:first-child {
              min-height: 200px;
            }
            li {
              text-align: left;
              width: 100%;
              min-height: 0;
              span {
                font-size: 1.3rem;
                text-transform: none;
                font-weight: bold;
                color: @mega-menu-text;
              }
            }
            h3 {
              border-bottom: 1px solid #666;
              font-weight: 700;
              margin: 0 0 2rem;
              padding: 0;
              .font-size(17);
              line-height: 11px;
              color: @mega-menu-text;
              font-family: Montserrat;
              padding-bottom: 3rem;
            }
        }
        .social-media-links {
          h3 {
            border-bottom: 1px solid #666;
            font-weight: 700;
            margin: 0 0 2rem;
            padding: 0;
            .font-size(17);
            line-height: 11px;
            color: @mega-menu-text;
            font-family: Montserrat;
            padding-bottom: 3rem;
          }
          a {
            margin-right: 14%;
            font-size: 3rem;
            color: lighten(@mega-menu-text,50);
            &:hover {
              color: @mega-menu-text;
              text-decoration: none;
            }
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
		// ul{
		// 	display:inline-block;
		// 	width:100%;
		// 	border-top:1px solid #656565;
		// 	vertical-align:top;
		// 	padding:2rem 0 2rem 3.2rem;
		// 	margin:0 5% 0 0;
		// 	li{
		// 		text-align:left;
    //     width:25%;
		// 		a{
		// 			display:inline-block;
		// 			padding:1rem 0;
		// 			span{
		// 				.font-size(17);
		// 			}
		// 		}
		// 	}
		// }
	}
	.search-bar{
		padding:3rem 0;
		position: absolute;
		width: 100%;
		left: 0;
		background-color: rgba(255,255,255,.9);
    top: 100%;
    border-top: 1px solid #999;
		>div{
			margin: auto;
			max-width: 1024px;
			padding: 0 32px;
			.search{		
				position: relative;
        width:95%;
        border: 0;
				input{
					width:100%;				
					padding:1rem 3rem 1rem 1rem;
					.font-size(24);
					outline-style:none;
					box-shadow:none;
					border-color:transparent;
					border-bottom:1px solid #999;
					background:none;
					color:#999;
					font-family:Montserrat;
					&::placeholder{
						color:#999;
					}
/* 					&::-webkit-input-placeholder {
						color:#999;
					}
						
					&:-moz-placeholder {
						color:#999; 
					}
						
					&::-moz-placeholder {
						color:#999;  
					}
						
					&:-ms-input-placeholder {  
						color:#999; 
					} */
				}
				button{
					position: absolute;
					right:0;
					background:url('@{img-path}/search.svg') no-repeat;
					height: 3rem;
					width: 3rem;
					border:0;
					cursor:pointer;
          top:1rem;
				}
			}
		}
	}
}

.dropdown-overlay{
	width:100vw;
	height:100vh;
	background:rgba(0,0,0,.5);
	position:fixed;
	top:0;
	z-index:9;
}

@media only screen and (max-width: 1267px) {
  header.page-header {
    .header-right {
      width: 84%;
    }
    #logo {
      width: 16%;
    }
  }
}

@media only screen and (max-width: 1073px) {
  header.page-header {
    .header-right {
      width: 81%;
      margin-left: 2rem;
    }
    #logo {
      width: 16%;
    }
  }
	// header.page-header {
	// 	.top-header-bar{
	// 		display:none;
	// 	}
	// 	#logo{
	// 		width:60%;
	// 		img{
	// 			max-width:100%;
	// 		}
	// 	}
	// 	.header-right{
	// 		width:40%;
	// 		position:static;
	// 		>ul{
	// 			justify-content: flex-end;
	// 			text-align:center;
	// 			width:5rem;
	// 			display:inline-block;
	// 			>li{
	// 				flex:1;
	// 				line-height:4rem;
	// 				&:not(.search){
	// 					display:none;
	// 				}
	// 				&.search{
	// 					max-width:5rem;
	// 				}
	// 				&.mobile-menu{
	// 					display:block;
	// 					max-width:5rem;
	// 				}
	// 				.search-toggle{	
	// 					vertical-align:middle;
	// 				}				
	// 			}
	// 		}
	// 		.mobile-nav{
	// 			display:inline-block;
	// 			max-width:50%;
	// 		}
	// 	}
	// }
}

@media only screen and (max-width: 946px) {
  header.page-header .header-right>ul>li a span {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: @default-tablet-sm) {
  .page-header{
    &.mobile {
      display: flex !important;
      .mobile-nav{
        width:5rem;			
        vertical-align:top;
        position:static;
        flex: 1;
        border-right: 1px solid;
        display: flex;
        .burger{
          padding: 1.3rem 1rem;
          height:5rem;
          cursor:pointer;
          margin: auto;
           span{
            width:3rem;
            height:2px;
            margin-bottom:9px;
            background-color:@default-text-color;
            transition:all .3s;
            opacity: 1;
          }
          &.open{
            span{
              &:first-child{
                transform:rotate(45deg) translate3d(6px,9px,0);
              }
              &:last-child{
                transform:rotate(-45deg) translate3d(-1px,-2px,0);
              }
              &:nth-child(2){
                display:none;
              }
            }
            & + .mobile-menu{
              display:block;
            }
          }
        }
        .mobile-menu{
          display:none;
          position:absolute;
          width:100vw;
          max-width:100%;
          box-sizing:border-box;
          left:0;
          background:@header-light-grey;
          top:5rem;
          li{
            width:100%;
            text-align: left;
            padding: 0 2rem;
            border-bottom: 1px solid #999;
            margin:0;
            &:first-child{
              border-top:1px solid #999;
            }
            a{
              display:block;						
              padding:1rem 0;
            }
          }
        }
      }
      #logo {
        flex: 3;
        border-right: 1px solid;
        display: flex;
        a {
          margin: auto;
        }
      }
      .search {
        border-right: 1px solid;
        padding: 0;
        button {
          background: transparent;
          border: 0;
          height: 100%;
          width: 100%;
          display: block;
          padding: 0;
          z-index: 99999999;
        }
        &:before {
          z-index: -1;
        }
      }
      .mobile-header-wishlist, .search {
        position: relative;
        flex: 1;
      }
      .search button {
        display: block;
        padding: 0;
        z-index: 99999999;
        width: 3rem;
        height: 4rem;
        background: url(/images/c/theme/hudson_reed/ui/search-mobile-header.svg) 50% 50% no-repeat;
        border: 0;
        outline: 0;
        cursor: pointer;
        margin: .5rem auto;
      }
      .mobile-header-wishlist:before {
        font-size: 3rem;
        top:10px;
        left: 21%;
        color: @light-grey-background-text-color;
      }
      .mobile-header-wishlist {
        padding: 0;
        &:before {
          left: 22%;
        }
        a {
          z-index: 99999999;
          display: block;
          width: 100%;
          height: 100%;
          padding: 0 1.5%;
          span {
            position: absolute;
            right: 18%;
            top: 30px;
            font-size: 1rem;
          }
        }
      }
      .search-bar.hidden {
        display: none;
      }
    }
    &.desktop {
      display: none;
    }
  }
}