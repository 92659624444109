#related {
  background: @off-gray;
  position: relative;
  margin: 0 30px;
  padding: 20px;
  margin-bottom: 20px;


    div {
        .element-fixer;
        width: 50%;
        
        &:first-child {
            border-right: 1px solid lighten(@gray-base, 10%);
            padding-right: 20px;
        }
    }

    #complete-the-look {
        padding-left: 20px;
    }

    h4 {
        margin-bottom: 15px;
        color: @default-text-color;
    }
}


#related ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

    li {
        .element-fixer;
        border: 1px solid #eef2f4;
        background: @white;
        margin: 0 2.0746888% 20px 0;
        width: 31.95020747%;

        &:nth-of-type(3n+3) {
            margin: 0 0 20px 0;
        }

        a {
            display: block;
            height: 118px;
            position: relative;
        }

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            position: absolute!important;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }

        &:hover {
            border: 1px solid lighten(@gray-base, 30%);
        }
    }
}

.save-percent{
    position:absolute;
    top:0;
    left:0;
}

.save-amount{
    //background: url("/images/save-amount-bg.png") 0 0 no-repeat;
    width:90px;
    height:90px;
    top:-11px;
    right:-10px;
    color:#fff;
    position:absolute;
}

.save-amount span{
    display:inline !important;
}

.save-amount-text{
    transform:rotate(45deg);
    -ms-transform:rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg);
    position: absolute;
    top: 27px;
    right: 1px;
}
.save-amount .value{
    margin-left: -2px;
}

@media only screen and (max-width: @default-tablet-lg) {
    #related ul li {
        width: 100%;
    }
}

@media only screen and (max-width: @default-mobile-sm) {
    #related {
        > div {
            display: block;
            width: 100%;
            padding: 0!important;
            border: 0!important;
        }
    }
}

