nav {
	background: rgba(000,000,000,0.5);
	min-height: 40px;
	ul {
		overflow: hidden;
		>li {
			.dib;
		}
		.intro-text {
			width: 40%;
			line-height: 40px;
			text-align: left;
		}
		.navigation {
			width: 60%;
		}
		li {
			.transition(@timing: 0.2s);
			position: relative;
			text-align: center;
			vertical-align: top;
			min-height: 40px;
			color: @white;
			width: 32.6417704%;
			a {
				padding: 6px 0;
				color: @white;
				text-decoration: none;
				text-transform: uppercase;
				.bezier;
				&:hover {
					text-decoration: none;
					box-shadow: inset 0 -4px 0 0 @hr-red;
					.bezier;
				}
			}
		}
		li:nth-child(2):after {
			border: 0;
		}
	}
}

@media only screen and (max-width: @default-mobile-sm) {
    nav {
        ul li a {
            .font-size(12);
        }
    }
}



// Nav Burger
/* .mobile-nav {
	display: none;
} */

@media only screen and (max-width: @default-tablet-sm) {
	.sub-nav,
	.main-nav {
		display: none;
	}
/* 	.open .mobile-nav {
		max-height: 532px;
	} */
/* 	.mobile-nav {
		padding-top: 50px;
	    .transition(@timing: 0.5s);
	    max-height: 0;
	    overflow: hidden;
	    display: block;
		li {
			display: block;
			text-align: left;
			background: #202020;
			width: 100%;
			border-bottom: 1px solid #848484;
			&:hover a,
			&:focus a,
			.current-page {
				color: @hr-red;
			}
			a {
				padding: 6px;
			}
			&:first-of-type {
				border-top: 1px solid #848484;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
		a:hover {
			box-shadow: none;
		}
	} */
	nav {
		background: #202020;
		display: block;
		position: relative;
	}
/* 	.burger {
		position: absolute;
		top: 13px;
		left: 15px;
		cursor: pointer;
		span {
			display: block;
			width: 30px;
			height: 4px;
			margin-bottom: 6px;
			background-color: @white;
			.transition(@timing: .3s);
			opacity: 1;
			&:last-child {
				margin-bottom: 0;
			}
			&:nth-child(2) {
				.transition(@timing: .5s);
			}
		}
	} */

/* 	.open .burger {
		span {
			.transition(@timing: .3s);
			&:first-child {
				transform: rotate(45deg) translate3d(5px,5px,0);
				margin-bottom: 10px;
			}
			&:nth-child(2) {
				display: none;
				opacity: 0;
				.transition(@timing: .5s);
			}
			&:last-child {
				transform: rotate(-45deg) translate3d(5px,-5px,0);
			}
		}
	} */
}
