.choosing {
    padding-bottom: 20px;
    h2,
    h4 {   
        color: @brand-base;
    }
    p {
        color: @default-text-color;;
        margin-bottom: 20px;

        &:last-child {
            margin: 0;
        }
    }

    .intro {
        background-color: @white;
        font-family: @font-family-base;
        margin: 0;
        .font-size(12);
        
        img{
            width: 100%;
        }
    }

    h2 {
        margin-bottom: 10px;
        .font-size(24);
        //font-weight: 300;
        font-weight:normal;
    }

    h4 {
        margin-bottom: 10px;
    }

    .pressure {
        p {
            .dib;
            width: 74%;
            margin: 0;
            .font-size(12)!important;
        }
    }

    .eco {
        img {
            float: left;
        }

        p {
            width: 80%;
            .dib;
            .font-size(12)!important;
            margin: 0 0 10px 0;
            line-height: 19px;
            padding-left: 10px;
        }
    }
}

.choosing-inner {
    >div {
        padding: 5px 0;
        clear: left;

        img {
            float: left;
            margin-right: 20px;
        }
    }
}

@media only screen and (max-width: @default-mobile-sm) {
    .choosing {
        .pressure, 
        .eco {
            margin-bottom: 10px;
            
            p {
                width: 100%;
                display: block;
                text-align: center;
            }

            img {
                margin: auto;
                display: block;
                float: none;
                margin-bottom: 10px;
            }
        } 
    }
}