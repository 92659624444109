.dib {
	display: inline-block;
	*display: inline;
	*zoom: 1;
}

.element-fixer {
	.dib;
	vertical-align: top;
	font-size: 0;
}

.transition(@timing) {
	-webkit-transition: all @timing;
	-o-transition: all @timing;
	-moz-transition: all @timing;
	-ms-transition: all @timing;
	transition: all @timing;
}

.bezier {
	transition: all .25s cubic-bezier(0.76, 0.28, 1, 1);
}

.border-radius(@radius) {
	-moz-border-radius: (@radius);
    -webkit-border-radius: (@radius);
    -khtml-border-radius: (@radius);
    border-radius: (@radius);
}

.border-radius-circle {
	-moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    border-radius: 50%;
    behavior: url(ie-css3.htc)
}

.font-size(@font-size: 16){
    @rem: (@font-size / 10);
    font-size: @font-size * 1px;
    font-size: ~"@{rem}rem";
}
