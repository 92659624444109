.input {
	position: relative;
	z-index: 1;
	display: inline-block;
	margin: 1em 0;
	max-width: 350px;
	display: block;
	height: 70px;
	vertical-align: top;
}

.form {
	padding-top: 10px;
}

.field{
    min-height: 45px;
}

.input__label {
	display: inline-block;
	float: right;
	padding: 0 1em;
	width: 40%;
	color: #6a7989;
	font-weight: bold;
	font-size: 70.25%;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.input__label-content {
	position: relative;
	display: block;
	padding: 1.6em 0;
	width: 100%;
	font-weight: 600;
}

.graphic {
	position: absolute;
	top: 0;
	left: 0;
	fill: none;
}

.icon {
	color: #ddd;
	font-size: 150%;
}

/* Isao */
.input__field--isao {
	z-index: 10;
	padding: 0.75em 0.5em 0.65em;
	width: 100%;
	background: transparent;
	color: @default-text-color;
	position: relative;
	display: block;
	float: right;
	border: none;
	border-radius: 0;
	background: @off-gray;
	-webkit-appearance: none; /* for box shadows to show on iOS */

	&:focus {
		outline: none;
	}
}

.input__label--isao {
	position: relative;
	overflow: hidden;
	padding: 0;
	font-weight: 600;
	width: 100%;
	color: @default-text-color;
	text-align: left;

	&:before {
		content: '';
		position: absolute;
		top: -4px;
		width: 100%;
		height: 7px;
		background: @gray-base;
		-webkit-transform: scale3d(1, 0.4, 1);
		transform: scale3d(1, 0.4, 1);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		-webkit-transition: -webkit-transform 0.3s, background-color 0.3s;
		transition: transform 0.3s, background-color 0.3s;
		-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	}

	&::after {
		content: attr(data-content);
		position: absolute;
		.font-size(14);
		top: 0;
		left: 0;
		padding: 0.75em 0.15em;
		color: @default-text-color;
		opacity: 0;
		-webkit-transform: translate3d(0, 50%, 0);
		transform: translate3d(0, 50%, 0);
		-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
		transition: opacity 0.3s, transform 0.3s;
		-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		pointer-events: none;
	} 
}

.input__field--isao:focus + .input__label--isao:before {
	background-color: @hr-red;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}

.input__field--isao:focus + .input__label--isao {
	pointer-events: none;
}

.input__field--isao:focus + .input__label--isao:after {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.input__label-content--isao {
	padding: 0.75em 0.15em;
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.input__field--isao:focus + .input__label--isao .input__label-content--isao {
	opacity: 0;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
}

#messagefield {
	margin-bottom: 15px;
}

// #message {
// 	background-color: @off-gray;
// 	color: @gray-base;
// 	width: 100%;
// 	font-family: @font-family-base;
// 	border: 0;
// 	padding: 5px;
// }
	

@media only screen and (max-width: @default-mobile-lg) {
	.input__field--isao {
		padding: 15px;
	}
	.input {
		height: 80px;
	}

	.field button {
		width: 100%;
		height: 60px;
		padding: 0;
	}
}