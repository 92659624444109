#trade-customer {
  .content-area {
    display: inline-block;
    display: flex;
    padding: 0;
    @media only screen and (max-width: @default-mobile-sm) and  (max-width: @default-mobile-lg){
      display: block;
      .toggle {
        width: 100% !important;
        display: block !important;
        padding-bottom: 0;
        form {
          padding-top: 0;
        }
      }
    }
    .toggle {
      flex: 1;
      width: 50%; // Flex fallback
      display: table-cell; // Flex fallback
      .sub-content {
        .input{
          height: 36px;
          max-width: inherit;
          // input {
          //   border: 1px solid darken(#F2F2F2,10);
          //   background-color: transparent;
          // }
        }
        p {
          min-height: 57px;
        }
        .button-container {
          text-align: right;
          .cta {
            text-transform: uppercase;
            padding: 8px 10px;
            width: 140px;
            &:before {
              position: absolute;
              right: 33px;
            }
          }
        }
      }
    }
  }
}