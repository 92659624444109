.tabs {
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;
    li {
        .dib;
        padding: 0;
        vertical-align: bottom;
        background: rgba(150,150,150,.7);
        margin-right: 5px;
        a {
            text-align: center;
            .font-size(16);
            text-decoration: none;
            position: relative;
            padding: 10px;
            display: block;
            color: @white;
            text-transform:uppercase;        
            span {
                font-family: @font-family-base;
                .dib;
                padding: 0 1px;
                .font-size(16);
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .active {
        background-color: @white;
        a {
            position: relative;  
            //color: @hr-red;
            color: @default-text-color;
            padding: 17px;
        }
        &.range-tab{
            a{
                //text-align:left;
                //font-family:Pistilli;
                font-size:1.6rem;                
                text-overflow: ellipsis;
                overflow:hidden;
                width:100%;
                white-space:nowrap;
            }
        }
    } 
}
.tabbed.opposite {
    .tabs {
        border-bottom: 1px solid @hr-base-text;
        overflow: visible;
        li {
            background: @off-gray;
            a {
                color: @light-grey-background-text-color;
                border: 0!important;
            }
        }
        li.active {
            border-top: 1px solid @hr-base-text;
            border-left: 1px solid @hr-base-text;
            border-right: 1px solid @hr-base-text;
            border-bottom: 1px solid @white;
            position: relative;
            bottom: -2px;
            z-index: 8;
            background: @white;
            a {
                //color: @hr-red;
                background: none;
            }
        }
        li.datasheet{
            background: @hr-red;
            float:right;
            position: relative;
            bottom:-12px;
            a{
                color:#fff;
            }
            &.active{
                bottom:0;
                a{
                    padding:10px;
                }
            }
        }
    }
    &.w-arr {        
        .tabs li.active {
            background: @white;
            a {
                position: relative;
                //color: @hr-red;
                padding: 17px;
            }
        }
    }
    &.w-arr.two-tab {
        .tabs li {
            width: 49%;
        }
    }
}
.la-tabs .tabs {
    padding: 0 20px;
}
@media only screen and (max-width: @default-mobile-lg) {
    .tabs li {
        a,
        a span {
            .font-size(12);
        }
    }
}
@media only screen and (max-width: @default-mobile-sm) {
    .tabs {
        li {
            a {
                padding: 10px!important;
                line-height: 19px;
                //height: 60px;
                height: 75px;
                width: 100%;                
            }
            &.active{
                border: 0;
                &.range-tab{
                    a{
                        font-size:1.4rem;
                    }
                }
            }
        }       
    }
}