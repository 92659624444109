.footer-pod-collection{
    max-width: @page-width;
	margin:5rem auto;
	font-size: 0;
    padding: 0 32px;
    width:100%;
    position: relative;
    display:flex !important;
    justify-content: space-between;
    display: table; //Flex fallback
    border-spacing: 2rem; //Flex fallback
    >section, > a{
        flex:1 1 auto;
        max-width:49.3%;
        background:@footer-pod;
        padding:3rem;
        width: 49.3%; //Flex fallback
        display: table-cell; //Flex fallback
        //max-height: 150px;
        &:first-child{
          padding: 0;
          margin-right: 1rem; //Flex fallback
          a {
            margin: 3rem;
            display: block;
            height: 100%;
          }
            &:hover{
                text-decoration:none;
                h1{
                    color:#fff;
                }
                span {
                  text-decoration: underline;
                }
            }
        }    
        span{
            text-transform: uppercase;
			      .font-size(30);
            color:#fff;
            letter-spacing:2px;
            margin:0 0 3rem 0;
        }
	}
	>a{
		&:hover{
			span{
				color:#fff;
			}
		}
	}
    .social-media-links{
        display: flex;
		justify-content: space-between;
		margin:10rem 0 0 0;
        >a{
            flex:1 1 100%;
			max-width:23%;
			img{
				max-width:100%;
			}
        }
    }
    .find-a-retailer{
      a:hover {
        text-decoration: none;
      }
      .input-section {
        display: flex;
        height: 30px;
        input {
          flex: 1;
          border: none;
          padding: 1rem;
          width: 82%; //Flex fallback
          height: 29px; //Flex fallback
          appearance: none;
          border-radius: 0;
        }
        button {
          background-color: #F2F2F2;
          border: none;
          flex: .3 0 30px;
          cursor: pointer;
          height: 29px; //Flex fallback
          width: 18%; //Flex fallback
          position: relative; //Flex fallback
          top: 0px; //Flex fallback
          right: 0px; //Flex fallback
        }
      }
    }
}
.mobile-h3 {
  margin-bottom: 0;
  font-size: 2rem;
  padding-bottom: 2rem;
  position: relative;
  width: 100%;
  &:before {
    position: absolute;
    right: 0;
    height: 22px;
    width: 20px;
    top: -3px;
  }
}

.footer-top {
  border-spacing: 2rem; //Flex fallback
  .footer-block {
    width: 25%; //Flex fallback
    display: table-cell; //Flex fallback
  }
}

.page-footer{
  background: @footer;
  a,h3,span {
    color:@footer-text !important;
  }
  a:hover {
    text-decoration: underline;
  }
  .social-media-links {
    a {
      font-size: 3rem;
      &:hover {
        text-decoration: none;
        color: @gray-base !important;
      }
    }
  }
  &.mobile {
    display: none;
    >div nav h3 {
      font-weight: normal;
    }
  }
  &.desktop {
    .footer-block {
      max-width: 26%;
      max-height: 388px;
      nav {
        min-height: 250px;
      }
      .contact {
        min-height: 253px;
        ul:first-of-type {
          margin-bottom: 1rem;
        }
        li a {
          color: @footer-text !important;
        }
      }
      .social-media-links {
        a {
          display: inline-block;
          &:not(:last-of-type){
            margin-right: 2rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: @default-mobile-sm) {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: inline-block;
    }
  }
  border-top:1px solid #DDD;
  background: @footer;
  position: absolute;
	hr{
		border:0;
		border-top:1px solid #DDD;
  }
  &.mobile{
    .footer-block {
      max-width: 100%;
      margin-bottom: 0;
      margin-top: 0;
      h3 {
        .mobile-h3();
        &:before{
          content: url(/images/c/theme/hudson_reed/ui/HR-Web-Plus.svg);
        }
        &.open:before {
          content: url(/images/c/theme/hudson_reed/ui/HR-Web-Minus.svg);
        }
      }
      ul {
        animation-name: fadeOut;
        display: none;
        -webkit-animation-duration: 1s;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: 1;
        &.fadeIn {
          animation-name: fadeIn;
          display: inline-block;
        }
      }
      .contact {
        margin-top: 5rem;
        ul{
          display: block;
          animation: none;
          &:first-of-type {
            margin-bottom: 1rem;
          }
          &:last-child {
            border-bottom: 1px solid #666;
            padding-bottom: 5rem;
          }
          li span {
            font-size: 1.8rem;
            text-transform: uppercase;
            font-weight: bold;
          }
        }
        h3{
          font-weight: normal;
          &:before {
            content: none;
          }
        }
      }
      .social-media-links {
        padding-bottom: 3rem;
        border-bottom: 1px solid #666;
        a{
          display: inline;
          margin-right: 2rem;
          &:last-of-type {
            margin-right: 0;
          }
        }
        h3 {
          .headings();
          border-bottom: 0;
          margin-top: 2rem;
          &:before {
            content: none;
          }
        }
      }
      .legal {
        display: block;
        animation: none;
        margin: 2rem 0;
        li span {
          font-style: italic;
        }
      }
    }
  }
	>div{
		display: flex;
		max-width: @page-width;
		margin:4rem auto;
		font-size:0;
		padding:0 32px;
    justify-content: space-between;
    
    nav,
    .contact,
    .social-media-links{
			background:none;
			flex:1 1 100%;
			padding:0;
			h3{
        border-bottom: 1px solid @regular-text-colour;
				text-transform:uppercase;
				font-weight:700;
				margin:0;
				padding:0;
				.font-size(13);
				line-height:11px;
				color:@regular-text-colour;
        font-family:Montserrat;
        margin-bottom: 2rem;
			}
			ul{
				li{
					text-align:left;
					width:100%;
					min-height:0;
					a{
						color:@regular-text-colour;
						.font-size(13);
						padding:0;
						line-height:36px;
						display:inline-block;
						&:hover{
              box-shadow:none;
              text-decoration: underline;
						}
						span{
							.font-size(13);
						}
					}
				}
			}
		}
	}
	.footer-middle{
		.footer-logo{
			a{
				margin:1rem 0;
			}
		}
		.social-media-links{
			display: flex;
			justify-content: space-between;
			margin:1rem 0;
			text-align:right;
			>a{
				flex:1 1 auto;
				margin:0 .5rem;
				//display:inline-block;
			}
		}
	}
	.footer-bottom{
    border-top: 1px solid #666;
    padding: 4rem 0 0;
    @media only screen and (max-width: 1024px) {
      margin: 0 32px;
    }
		ul{
			flex:1 1 24rem;
			&:first-of-type{
				flex:2 1 48rem;
      }
      li {
        display: inline-block;
        font-style: italic;
        margin-right: 4rem;
      }
			a{
				display:inline-block;
				&:hover{
          span{
            text-decoration: underline;
          }
				}
			}
			span{
				color:@regular-text-colour;
				.font-size(13);
				padding:0;
				line-height:29px;
				display:inline-block;
				text-transform:uppercase;
			}
		}
	}
}
@media only screen and (max-width: @default-tablet-lg) {
	.footer-pod-collection{
		flex-direction:column;
		>a,>section{
			max-width:100%;
      margin:1rem 0;
      width: 100%;
		}
	}
	.page-footer{
		>div{
			flex-wrap:wrap;
			margin:2rem auto;
      nav,
      .contact,
      .social-media-links{
				flex-basis:46%;
				margin:0 2%;
				padding:1rem 0;
				&:nth-child(n+3){
					border-top:1px solid #ddd;
				}
			}
		}
		.footer-bottom{
			flex-direction:column;
			display:block;
			ul{
				margin:1rem 0;
				&:last-of-type{
					li{
						display:inline-block;
						//margin:0 .5rem 0 0;
					}
				}
			}
		}
	}
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      //transform: translateY(-50px);
  } 
  100% {
      opacity: 1;
      //transform: translateY(0%);
  }
}

@keyframes fadeOut {
  0% {
      opacity: 1;
      //transform: translateY(0%);
  } 
  100% {
      opacity: 0;
      //transform: translateY(100px);
  }
}

.headings {
  border-bottom: 1px solid @regular-text-colour;
  text-transform:uppercase;
  font-weight:700;
  margin:0;
  padding:0;
  .font-size(13);
  line-height:11px;
  color:@regular-text-colour;
  font-family:Montserrat;
  margin-bottom: 2rem;
}