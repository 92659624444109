.product-information {
    display:inline-block;
    width:37.5%;
    vertical-align:top;
    flex:1;
    padding:0 0 0 3rem;
    h1{
        color:#333;
        text-transform: uppercase;
        span{
            .font-size(24);
        }
    }
    span{
        display:inline-block;
    }
    .range-name{
        margin:0 0 1rem 0;
    }
    .product-code, .range-name{
        color:@regular-text-colour;
        .font-size(20);
        text-transform: uppercase;
    }
    .price-container{
        margin:2rem 0;
        .price{
            font-weight:700;
            .font-size(20);
            display:block;
            span{
                .font-size(20);
            }
        }
        .includes-vat{
            .font-size(12);
            color:@regular-text-colour;
            margin:.5rem 0 0 0;
        }
    }
    .prod-wishlist{
        background:none;
        width:auto;
        height:auto;
        box-shadow:none;
        text-indent:0;
        color:#74A2AD;
        .font-size(16);
        text-decoration:underline;
        text-transform:uppercase;
        padding:0;
        outline:none;
    }
    .product-detail-list{
        margin:2rem 0;
        li{
            font-weight:700;
            text-transform:uppercase;
            .font-size(16);
            span{
                .font-size(16);
                font-weight:400;
            }
        }
    }
    .find-your-local-retailer {
      margin-top: 2rem;
      background-color: #a3a3a3;
      color: #fff;
      span {
        color: #fff;
      }
      &:hover {
        margin-top: 2rem;
        background-color: #fff;
        color: #a3a3a3;
        border: 1px solid #a3a3a3;
        span {
          color: #a3a3a3;
        }
      }
    }
}

.product-buckets{
    margin:4rem 0;
    .tabs{
        width:100%;
        margin:0;
        display:flex;
        padding:0 2rem;
        li{
            background:#888;
            margin:0;
            width:33%;
            flex:1;
            &:not(:last-of-type){
                margin:0 1px 0 0;
            }
            &.active{
                background:#F5F5F5;
                a{
                    color:@regular-text-colour;
                    padding:1rem;
                }
            }
            a{
                color:#F0F0F0;
                .font-size(18);
                text-transform:uppercase;
                letter-spacing:2px;   
                font-family: @font-family-base;           
            }
        }
    }
    .tab-content{
        padding:2rem;
        border-top:1px solid #888;
        position:relative;
    }
    .bucket-contents{
        display:flex;
        width:100%;
        li{
            display:inline-block;
            width:20%;
            padding:1rem;
            text-align:center;
            flex:1;
            a{
                line-height:200px;
                img{
                    max-width:100%;
                    vertical-align: middle;
                    max-height:200px;
                }
                p{
                    margin:1rem 0;
                }
                &:hover{
                    text-decoration:none;
                    p{                        
                        color:#000;
                    }
                } 
            }
        }
    }
}
.category-buckets{
    //mostly same as product buckets execpt added func for lots of products - paging
    .tab-content{
        >ul{
            &:after{
                content:'';
                position:absolute;
                cursor:pointer;
                width:2rem;
                height:2rem;
                background:#000;
            }
        }
    }
}

@media only screen and (max-width: @default-tablet-lg) {
    .product-information {
        width:100%;
        padding:0;
    }

    .product-buckets{
        .tabs{
            min-height:auto;
            padding:0;
            li{
                a{
                    border:none;
                    height:initial;
                    .font-size(16);
                }
            }
        }
    }
}