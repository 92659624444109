.intro {
    padding: @page-padding;
    padding-top: 0;
    .technical-wrap {
        .element-fixer;
        width: 75%;
        padding: 20px;
        a {
            .font-size(12);
            color: @default-text-color;
            text-decoration: underline;
        }
        span {
            .font-size(12);
            line-height: 19px;
        }
    }
    .technical-img-wrap {
        .element-fixer;
        width: 25%;
        img {
            width: 100%;
            height: auto;
        }
    }
    p, span {
        .font-size(16);
        color: @default-text-color;
        a,
        span {
            .dib;
        }
    }
}

.technical-selection {
  background: @off-gray;
  margin-bottom: 2px;
  padding: 20px;
    h3 {
        color: @default-text-color; 
        .font-size(20);
        margin: 0;
        .dib;
    }
    >a {
        width: 100%;
    }
    select {
        .dib;
        width: 225px;
        .font-size(16);
        float: right;
    }
}
.technical-selection-result {
    padding: 40px;
    display: none;
    background: @white;
    margin-top: 25px;
    h4 {
      color: @default-text-color;
        margin-top:0;
    }
    a {
        width: 100%;
        word-wrap: break-word;
        padding: 14px 0 0 50px;
        padding-left: 50px;
        background: url("@{img-path}/pdf-icon.png") 0 50% no-repeat;
        .dib;
        .font-size(18);
    }
}
.technical-selection-result.selected {
    display:block;
}
#line-drawings .result-content {
    text-align: center;
    img {
        width: 100%;
    }
}
.video-guides ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.video {
    margin: 20px 0;
    width: 100%!important;
    clear: right;
    h3 {
      color: @default-text-color;
        line-height: 39px;
        .font-size(24);
    }
    .duration {
        .font-size(14);
        color: @default-text-color;
    }
    a {
        .dib;
    }

    // video, >a {
    //     float: right;
    // }
}

.video-info {
    width: 51%;
    .element-fixer;
}
@media only screen and (max-width: @default-tablet-lg) {
    .video {
        text-align: center;

         video, a {
            float: none;
        }
    }
    .video-info {
        width: 100%;
    }   
}

@media only screen and (max-width: @default-mobile-lg) {
    .technical-selection {
        height: 100px;

        h3 {
            display: block;
            .font-size(16);
        }

        select {
            display: block;
            width: 100%;
            .font-size(14);
        }
    }

    .intro .technical-wrap {
        display: block;
        width: 100%;
    }

    .intro .technical-img-wrap {
        display: none;
    }
}