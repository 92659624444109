.sub-content {
	background: @white;
	padding: 20px;
	img, .download-brochures {
		width: 50%;
		height: auto;
	}
	h3 {
		//color: @hr-red;
		color: @default-text-color;
	}
	h4 {
		margin: 20px 0; 
		//color: @hr-red;
		color: @default-text-color;
	}
	.download-brochures {
		text-align: center;
		padding: 7% 0;
		.element-fixer;
		li {
			display: list-item;
			color: @brand-base;
			list-style-type: disc;
			width: 50%;
    		margin: auto;
    		text-align: left;	
		}
		a,span {
			.dib;
			.font-size(18);
			color: @brand-base;
			&:hover {
				text-decoration: none;
			}
		}
	}
}
.header {
	h2 {
		color: @default-text-color;
		margin: 0;
		line-height: 32px;
		span {
			color: @default-text-color;
			.dib;
			//font-family: @font-family-titles;
		}
	}
}
#brochure-form {
	.sub-content {
		padding: @page-padding * 2;
		p {
			.font-size(12);
			line-height: 19px;
		}
		h3 {
			color: @brand-base;
		}
	}	
}
.toggle {
	.header {
		//background: @off-gray url("@{img-path}/accordion-arrow-down.png") 100% 50% no-repeat;		
		//background: @off-gray url("@{img-path}/HR-Web-Arrow-Small-Up.svg") 98% 50% no-repeat;
		//background-size:2rem;
	  line-height: 84px;
	  position: relative;
	  cursor: pointer;
    padding: 25px;
    width: 100%;
    background-color: @off-gray;
    &:before {
      font-size: 2rem;
      position: absolute;
      right: 27px;
      top: 0;
    }
	}
	.header.active {
		//background-image: url("@{img-path}/accordion-arrow.png");
		//background-image: url("@{img-path}/HR-Web-Arrow-Small-Down-Hover.svg");
    }
}
@media only screen and (max-width: @default-tablet-sm) {
	.sub-content {
		img, .download-brochures {
			display: block;
			width: 100%;
		}
	}
}
@media only screen and (max-width: @default-mobile-lg) {
	.download-brochures {
		li {
			width: 40%!important;
			a, span {
				.font-size(14)!important;
			}
		}
	}
}
@media only screen and (max-width: @default-mobile-sm) {
	.download-brochures {
		li {
			width: 75%!important;
		}
	}
	.toggle {
		.header {
			background-position-y: 20px;
		}
	}
}
.brochures {
	.content_intro {
		position: relative;
		text-align:center;
		h1{
			text-transform:uppercase;
		}
		p{
			max-width: 40rem;
			display: inline-block;
		}
	}
	h2 {
		.font-size(24);
		color: @default-text-color;
		font-weight: 300;
		line-height: 34px;
		margin-bottom: 0;
	}
	.content-area {
		background-color: @white;
		border-top: 1px solid lighten(@gray-base, 40%);
		padding:3rem 0;
	}
	.downloads{
		background-color: @white;
		padding:3rem 0;
	}
	.brochure-request-section{
		border-top: 1px solid lighten(@gray-base, 40%);
		border-bottom: 1px solid lighten(@gray-base, 40%);
		padding:4rem 0;
	}
	a:hover {
		text-decoration: none;
	}
}
.downloads {
	h3 {
		color: @default-text-color;
		.font-size(15);
	}
}
.download-list, .add-download-list {
	.element-fixer;
	h3 {
		padding-left: 8px;
	}
	ul li {
		padding: 15px;
		.element-fixer;
		width: 33.333333%;
		text-align:center;
		a{
			&:hover{
				span{
					color:@link-hover-colour;
				}
			}
		}
		img {
			height: auto;
			width: 100%;
		}
		span{
			font-family: @font-family-base;
			display: block;
			.font-size(13);
			min-height: 23px;
			line-height: 19px;
			margin: 10px 0 0 0;
			&:first-of-type{
				font-weight:700;
			}
			&:last-of-type{
				text-decoration:underline;
			}
		}
	}
}
.brochure-req {
	position:relative;
	#brochure-request {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 45%;
	}
}
.brochure-section {
	padding:2rem 0;
	>div {
		.element-fixer;
		p {
			.font-size(12);
			color: @default-text-color;
			font-family: Arial;
			line-height: 19px;
		}
	}
	form {
		padding:0;
		.element-fixer;
		width:100%;
		.contact-information {
			width: 50%;
			.element-fixer;
			padding: 10px;
      input,
      select {
			    border: 0;
			    padding: 15px;
			    border-bottom: 1px solid #eee;
        width: 100%;
        color: @default-text-color;
				&:focus{
					color:#000;
					border-bottom:1px solid #000;
					outline:none;
					&::placeholder{
						color:#000;
					}
				}
      }
      select {
        padding: 15px 10px;
      }
    }
    .button-container{
      text-align: right;
      button {
              .font-size(14);
        text-transform: uppercase;
        width: 240px!important;
          line-height: 18px;
          margin-top: 20px;
      }
    }
	}
}
@media only screen and (max-width: @default-tablet-lg) {
	.download-list, .add-download-list {
		ul li {
			padding: 10px;	
		}
	}
	.downloads {
		h3 {
			min-height: 54px;
		}
	}
}

@media only screen and (max-width: @default-tablet-sm) {
	.download-list, .add-download-list {
		width:100%;
		display:block;
		ul{
			li{
				width:50%;
			}
		}
	}
	.downloads {
		h3 {
			min-height: initial;
		}
	}
	.brochure-section {
		>div, form {
			width: 100%;			
		}
	}
}
@media only screen and (max-width: @default-mobile-lg) {
	.brochure-req {
		h2 {
			.font-size(20);
		}
		#brochure-request {
			width: 50%;
		}
	} 
}
@media only screen and (max-width: @default-mobile-sm) {
	.brochures h2 {
		.font-size(18);
	}
	.download-list, .add-download-list {
		width: 100%!important;
		&:nth-of-type(2) ul li {
			width: 100%;
		}
		ul li {
			border-bottom: 1px solid lighten(@gray-base, 40%);
			margin-bottom: 10px;
		}
	}
	.brochure-req {
		h2 {
			width: 100%;
		}
		#brochure-request {
			display: none;
		}
	}
	.brochure-section {
		form {
			.contact-information {
				width: 100%;
				padding:0 1rem;
			}
			button {
				width: 100%!important;
			}
		}
	}
}