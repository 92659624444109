#contact-map {
    background: @white;
    padding: @page-padding * 2;
    margin:2rem 0;
    position: relative;
    border: 1px solid #d2dade;
    h3, p {
        .font-size(14);
        margin: 0;
    }
}
#contact-info,.map {
    .element-fixer;
    width: 100%;
}
.map {
    border: 1px solid #79797a;
    height: 365px;
}
.contact {
  padding-bottom: 0;
    .intro {
      display: block;
      padding-bottom: 0;
      .address-details {
        margin-bottom: 4rem;
      }
        .contact-info, .address-details {
          padding-left: 20px;
          p {
            .font-size(12)!important;
            margin-bottom: 15px;
            line-height: 19px;
            color: @default-text-color;
            .heading {
              font-weight: bold;
            }
            font-size: 1.2rem;
          }
        }
    }
}
#contact-faq, #email-us {
    .sub-content {
        border: 1px solid #d2dade;
        margin:2rem 0;
        padding: 30px;
        >div {
            border: 1px solid #d2dade;
            padding: 30px;
        }
        p, span {
            color: @default-text-color;
            .font-size(12);
            line-height: 19px;
        }
    }
}

#email-us .sub-content p.form-error,
#email-us .sub-content p.form-error span,
#email-us .sub-content p.form-error a {
  color: red;
}

.grecaptcha-badge {
  z-index: 1;
}

#email-us {
  form {
    width: 50%;
    @media only screen and (max-width: @default-mobile-lg) and (max-width: @default-mobile-sm){
      width: 100%;
    }
  }
}
// #message-type-field {
// 	margin: 14px 0;
// 	select {
// 		background: @off-gray;
// 		border: 1px solid @off-gray;
// 		color: @gray-base;
// 		height: 35px;
// 		width: 100%;
// 	}
// }
@media only screen and (max-width: @default-mobile-lg) {
    #contact-map {
        > div {
            //display: block;
            width: 100%;
        }

        .map {
            margin-bottom: 20px;
        }

        #contact-info {
            padding: 0; 
        }
    }
    #contact-faq, #email-us {
        .sub-content {
            margin: 10px;
            padding: 15px;

            >div {
                padding: 15px;
            }
        }
    }
	#message-type-field select {
		height: 45px;
	}
}