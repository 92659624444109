//MAP - tab change on product page
.tabbed.product-tabs{
    .dib;
    width:100%;
    background:#F5F5F5;
    margin:2rem 0;
    vertical-align:top;
    .tabs{
        width:100%;
        margin:0;
        display: flex;
        li{
            background:#888;
            margin:0;
            flex: 1;
            width: 33.2%; // Flex fallback
            @media only screen and (max-width: @default-mobile-sm) {
              max-width: 33%;
              a {
                word-wrap: break-word;
              }
            }
            &:not(:last-of-type){
              margin-right: 1px;
            }
            &.active{
                background:#F5F5F5;
                a{
                    color:@regular-text-colour;
                    padding:1rem;
                }
            }
            a{
                color:#F0F0F0;
                .font-size(18);
                text-transform:uppercase;
            }
        }
    }
    .product-tab{
        padding:2rem 3rem;
        #features-content{
            ul{
                list-style:disc;
                padding:0 0 0 2rem;
                li{
                    color:@regular-text-colour;
                }
            }
        }
    }
}
.product-local-retailer{
    .dib;
    vertical-align:top;
    padding:2rem;
    background:#888;
    margin:7rem 0 2rem 3.4%;
    width:30%;
    &:hover{
        text-decoration:none;
        background:@hr-highlight;
    }
    span{
        color:#f0f0f0;
        text-transform:uppercase;
        .font-size(20);
        letter-spacing:2px;
        width:59%;
        .dib
    }
    img{
        float:right;
        max-width:100px;
        margin:4rem 0 0 0;
    }
}
//MAP redesign
.specification-links{
    li{
        padding:0 0 0 2.5rem;
        position:relative;
        border-bottom:1px solid #74a2ad;
        &:not(:first-of-type) {
          padding-top: 1rem;
        }
        &:before{
            content:"";
            position: absolute;
            left:0;
            width:2rem;
            height:2rem;
        }
        &.image-spec{
            &:before{
                background:url("@{img-path}/jpeg-file.svg") no-repeat;
            }

        }
        &.tech-spec{
            &:before{
                background:url("@{img-path}/pdf-file.svg") no-repeat;
            }
        }
        a{
            .font-size(16);
            color:#74a2ad;
            display:inline;
        }
    }
}

.characteristic-specs{    
    margin:20px 0;
    text-align:left;
    width:100%;
    tr{
        border-bottom:1px solid #74a2ad;
        >th, >td{
            padding:1rem 0;
            .font-size(16);
        }
    }
}

@media only screen and (max-width: @default-tablet-lg) {
    .product-local-retailer{
        display:none;
    }
    .tabbed.product-tabs{
        width:100%;
        .tabs{
            min-height:auto;
            li{
                a{
                    border:none;
                }
            }
        }
    }
}

@media only screen and (max-width: @default-mobile-sm) {
  .tabbed.product-tabs .tabs li a{
    font-size: 1rem;
  }
    #features-and-benefits-info {
        border-bottom: 0;
        .column {
            display: block;
            border-right: 0;
            border-bottom: 1px solid lighten(@gray-base, 40%);
            padding: 0 30px 20px!important;
            height: inherit;
            text-align: center;
            &:last-child {
                border-bottom: 0;
            }
        }
        #dimension-column table {
            width: 100%;
        }
        #guarantee-column {
            margin-bottom: 10px;

        }
    }
}
