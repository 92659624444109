#online-stockists {
  padding: 20px 40px;
  .intro {
      padding-bottom: 40px;
      border-bottom: 1px solid @gray-base;
      img {
          width: 22%;
          .dib;
      }
      >div {
          width: 78%;
          padding-left: 5%;
          .dib;
      }
  }
  h3 {
    color: @default-text-color;
  }
  h2 {
      .font-size(20);
      //color: @hr-red;
      line-height: 33px;
      font-family:Monserrat;
      text-transform:uppercase;
  }
  p {
      .font-size(14);
      color: @default-text-color;
      line-height: 23px;
  }

  /*.toggle {
      padding-bottom: 30px;
      .sub-content{
        &:not(.hidden) {
          display: flex;
        }
        img {
          width: auto;
          height: auto;
        }
        &.hidden {
          display: none;
        }
        .content, .image {
          flex: 1;
          width: 50%; // Flex fallback
          display:table-cell; // Flex fallback
          vertical-align: top; // Flex fallback
        }
        .image {
          text-align: right;
        }
      }
      // > li {
      //     border-bottom: 1px solid lighten(@gray-base, 40%);
      //     padding: 20px 0;
      //     font-size: 0;
      //     > div {
      //         .element-fixer;
      //         width: 50%;
      //         &:last-child {
      //             text-align: right;
      //         }
      //     }
      //     h3 {
      //         font-family: @font-family-titles;
      //         //color: @hr-red;
      //         color: @gray-darker;
      //         .font-size(22);
      //         font-weight: 400;
      //     }
      //     ul {
      //         .font-size(14);
      //         color: #000;
      //         margin: 15px 40px;
      //         li {
      //             list-style-type: circle;
      //             .font-size(14);
      //             color: @gray-darker;
      //             font-family: @font-family-base;
      //         }
      //     }
      // }
  }*/
  .stockists {
    padding-bottom: 30px;
    >li {
      padding: 20px 0;
      font-size: 0;
      border-bottom: 1px solid #dfdfdf;
      >div {
        display: inline-block;
        vertical-align: top;
        font-size: 0;
        width: 50%;
        &:last-child {
          text-align: right;
        }
        @media only screen and (max-width: @default-mobile-sm) and  (max-width: @default-mobile-lg){
          width: 100%;
          display: block;   
          &:last-child {
            width: 64%;
          }     
        }
        // h3 {
        //   font-family: Pistilli;
        //   color: @default-text-color;
        //   font-size: 22px;
        //   font-size: 2.2rem;
        //   font-weight: 400;
        // }
        p {
          font-size: 14px;
          font-size: 1.4rem;
          color: @default-text-color;
          line-height: 23px;
        }
      }
    }
  }
}

@media only screen and (max-width: @default-tablet-sm) {
    .stockists {
        li > div {
            width: 100%;
            display: block;

            &:first-child {
                margin-bottom: 20px;
            }

            &:last-child {
                width: 75%;
            }

            img {
                width: 100%;
            } 
        }
    }
}

@media only screen and (max-width: @default-mobile-lg) {
    #online-stockists {
        padding: 20px;
        .intro {
            img {
                display: none;
            }

            >div {
                width: 100%;
            }
        }
    }
}