.extra-content {
	margin-bottom: 60px;
	.teaser {
		bottom: 0;
		position: absolute;
		width: 100%;
		padding: 5px 20px;
		.transition(@timing: 0.2s);
		h4 {
			.font-size(13);
			color: @white;
			height: auto;
		}
	}
	a:hover .teaser {
		bottom: 0;
	}
	p {
		display: block;
		.font-size(11)!important;
		color: @white;
		line-height: 19px;
		min-height: 38px;
	}
	.box .arr:after {
		border-left: 8px solid #fff;
        top: 0;
        bottom: 0;
        right: 20px;
        margin: auto;
	}
}
.new-products .teaser {
	background: rgba(161,34,77,0.8);
}
.featured-collection .teaser {
	background: rgba(107,108,108,0.8);
}
.sub-box {
	.element-fixer;
	width: 100%;
	.sub-box-wrap {
		display: table;
		vertical-align: bottom;
    	height: 100%;
	}
	>div {
		position: relative;
		.element-fixer;
		height: 90px;
		background: @hr-black;
		border-top: 3px solid @hr-red;
		.overlay {
			position: relative;
		    width: 100%;
		    position: absolute;
		    height: 87px;
		    .dib;
		    z-index: 2;
		}
		span {
			color: @white;
			.font-size(15);
		    z-index: 1;
		    width: 110px;
		    text-align: left;
		    position: absolute;
		    left: 10%;
		    bottom: 15px;
		    line-height: 19px;
		    text-transform: uppercase;
		    font-family: @font-family-base;
		}
		a {
			&:hover span {
				color: @default-text-color;
			}

			&:hover .arr:after {
				border-left: 8px solid @hr-red;
			}
		}
		i {
			height: 100%;
			width: 100%;
			right: 0;
			.dib;
			position: absolute;
		}
	}
	.sub-box-1 {
		i {
			background: url('@{img-path-1}/HR-Pod-1.jpg') 100% no-repeat;
		}
	}
	.sub-box-2 {
		i {
			background: url('@{img-path-1}/HR-Brochure-POD.jpg') 100% no-repeat;
		}
	}
	.sub-box-3 {
		i {
			background: url('@{img-path-1}/HR-Pod-3.jpg') 100% no-repeat;
		}
	}
}

.sellers {
	background-color: @white;
	h3 {
	    text-transform: uppercase;
	    .font-size(16);
	    line-height: 19px;
	    color: @default-text-color;
	    padding-left: 30px;
	    margin: 20px 0;
	}

	.box {
		padding: 0;
		a {
			border: 0;
			height: 100%;
			width: 100%;
		}
		img {
			position: static;
		}
	}
}


@media only screen and (max-width: @default-tablet-sm) {
	.sellers {
		h3 {
			.font-size(14);
		}
	}
	.sub-box {
		display: none;
	}
	.extra-content {
		.teaser {
			bottom: 0;
			h4 {
				margin-bottom: 0;
				.font-size(11);
			}
			p {
				display: none;
			}
		}
		.box .arr:after {
			top: 39%;
		}
		a:hover .teaser {
			bottom: 0px;
		}
	}
}

@media only screen and (max-width: @default-mobile-lg) {
	.sellers {
		.box {
			width: 100%;
			display: block;
		}
	}
	.sub-box {
		>div {
			display: block;
			width: 100%;
		}
	}
}

@media only screen and (max-width: @default-mobile-sm) {
	.extra-content .teaser {
		bottom: 0;
	}
}
