.home {
  .slider-container {
    @media only screen and (min-width: 320px) and (max-width: 320px) {
      max-height: 78vh;
    }
    .home-carousel {
      @media only screen and (min-width: 320px) and (max-width: 320px) {
        max-height: 78vh;
      }
      .carousel-item-list .carousel-item .slide-content-layer {
        bottom: 25%;
        @media only screen and (min-height: 415px) and (max-height: 811px) {
          bottom: 12%
        }
        @media only screen and (min-width: 320px) and (max-width: 320px) {
          bottom: 0%;
        }
        @media only screen and (min-width: 375px) and (max-width: 375px) {
          bottom: 16%;
        }
        .slide-content {
          min-height: 330px;
          @media only screen and (max-width: @default-mobile-sm) and (max-width: @default-mobile-lg){
            min-height: 350px;
            h1 {
              font-size: 3.9rem;
            }
          }
          @media only screen and (min-width: 1024px) and (max-width: 1024px) {
            min-height: 360px;
          }
          @media only screen and (min-width: 768px) and (max-width: 768px) {
            min-height: 302px;
          }
          @media only screen and (min-width: 320px) and (max-width: 320px) {
            h1 {
              font-size: 3.2rem;
            }
          }
        }
      }
      .carousel-item-markers {
        @media only screen and (min-width: 768px) and (max-width: 768px) {
          bottom: 22%;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1024px) {
          bottom: 19%;
        }
        @media only screen and (min-width: 320px) and (max-width: 320px) {
          bottom: 1%;
        }
        @media only screen and (min-height: 415px) and (max-height: 811px) {
          bottom: 4%;
        }
      }
    }
    .slide-content span {
      text-decoration: none !important;
    }
  }
  .parralax-container{
    .home-pod {
      position: relative;
      display: flex;
      &.new-products {
        padding-top: 50px;
        .image-container {
          max-width: 66%;
        }
        .home-pod-content {
          right: 4%;
          a {
            margin-left: 37%;
          }
        }
      }
      &.new-furniture {
        justify-content: flex-end;
        padding-bottom: 50px;
        text-align: right; // Flex fallback
        .image-container {
          //flex: .5;
          flex: none;
          max-width: 50%;
        }
        .home-pod-content {
          left: 14%;
        }
      }
      >div {
        display: inline-block;
        flex: 1;
      }
      .image-container {
        img{
            width:100%;
        }
      }
      .home-pod-content {
        max-width: 40%;
        position: absolute;
        top: 25%;
        text-align: left;
      }
    }
    .text-container {
      max-width: 40%;
      position: relative;
      top: 7rem;
      h3 {
        color: @default-text-color;
        text-decoration: underline;
      }

    }
  }

  @media only screen and (max-width: @default-mobile-sm) and (max-width: @default-mobile-lg){
    .parralax-container {
      .home-pod {
        height: 100%;
        &.new-products {
          padding-top: 112px;
          margin-left: 0 !important;
          a {
            margin-left: 0 !important;
          }
          .image-container {
            max-width: 90%;
          }
          .home-pod-content {
            position: absolute;
            top: 1%;
            left: 11%;
            height: 100%;
            h1 {
              position: absolute;
              top: 5%;
            }
            a {
              position: absolute;
              bottom: -12%;
            }
          }
        }
        &.new-furniture {
          margin-top: 6rem;
          margin-right: 0 !important;
          padding-bottom: 8rem;
          .image-container {
            max-width: 100%;
            flex: 1;
            width: 100%;
            img {
              filter: none;
            }
          }
          .home-pod-content {
            height: 100%;
            position: absolute;
            top: 0;
            left: 11% !important;
            h1 {
              position: absolute;
              top: -21%;
            }
            a {
              position: absolute;
              bottom: 7%;
            }
          }
        }
      }
      .text-container {
        max-width: 76%;
        margin-left: 2rem;
        top: 2rem;
        margin-top: 5rem;
        margin-bottom: 12rem;
      }
    }
  }

  @media only screen and (max-width: 1024px){
    .parralax-container{
      .text-container {
        margin-left: 4rem;
      }
      .home-pod{
        &.new-products {
          margin-left: 4rem;
        }
        &.new-furniture {
          margin-right: 4rem;
          .home-pod-content {
            left: 23%;
          }
        }
        .home-pod-content{
          margin:0 0 5% 5%;
          max-width:100%;
          h1{
              .font-size(36);
              max-width:80%;
          }
        }
      }
    }
  }
}