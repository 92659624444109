.stop-scroll{
    overflow:hidden;
}
.arr {
    position: relative;
/*      &:after {
        position: absolute;
        .dib;
        content: "";
        right: 0;
        bottom: 6px;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 8px solid @white;
    } */
}

.cta {
    .transition(@timing: 0.2s);
}

.prod-wishlist {
    font-family:@font-family-base;
    .dib;
    text-decoration: none;
    cursor: pointer;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.2);
    box-shadow: 0 1px 1px rgba(0,0,0,0.2);
    .font-size(14);
    border: 0;
    height: 28px;
    width: 134px;
    text-indent: 4px;
    text-align: left;
    background: #000 url("@{img-path}/star.png") 95% 50% no-repeat;
    color: #fff;

    &:hover {
        background: #000 url("@{img-path}/pink-star.png") 95% 50% no-repeat;
    }
}

.lvl-1 {
    height: 345;
}
.lvl-2 {
    height: 345 * 2;
}
.lvl-3 {
    height: 345 * 3;
}

.btn {
    .dib;
    .font-size(12);
    padding: 0 10px;
    background: @hr-red;
    color: @white;
    text-align: center;
    text-transform: capitalize;
    padding: 15px 10px;
    cursor: pointer;
    text-decoration: none;
    border: 0;
    width: 100px;
    .bezier;

    &:after {
        border: 0;
    }
    &:hover {
        text-decoration: none;
        //box-shadow: inset 0 -4px 0 0 @hr-highlight;
        background:@hr-highlight;
        .bezier;
    }
}

.toggle {
    padding-bottom: 20px;
}

// Product && Level Boxes
.box {
    .element-fixer;
    padding: 10px;
    position: relative;
    width: 33.333333%;
    overflow: hidden;

    .overlay,
    .discounted {
        position: absolute!important;
        bottom: 0;
        margin: 0;
        width: auto!important;
    }

    .overlay {
        left: 0;
        top: 0;
        height: 40px!important;
    }

    .discounted {
        left: initial;
        right: -11px;
        top: -12px;
    }

    .cta {
        width: 100%;
        display: block;
        height: 100%;
        //overflow: hidden;
        .font-size(16);
        color: @default-text-color;
        font-family:@font-family-base;
    }

    &:hover {
        .teaser {
            //border-bottom: 1px solid @hr-red;
        }

        .cta {
            //color: @hr-red;
            color:@link-hover-colour;
        }
        p{
            color:@link-hover-colour;
            text-decoration: underline;
        }

        .arr:after {
            border-left: 8px solid @hr-red!important;
        }
    }

    .cta.ellipsis:after{
        content: '. . .';
        position: absolute;
        right: 0;
        bottom: 5px;
        width: 45px;
        text-align: right;
        line-height: 20px;
        background: -moz-linear-gradient(left,  rgba(255,255,255,0) 1%, rgba(255,255,255,0) 25%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 1%,rgba(255,255,255,0) 25%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(255,255,255,0) 1%,rgba(255,255,255,0) 25%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }

    .checkbox {
        z-index: 1;
        position: relative;
    }
    .product-docs{
        padding:20px 10px 10px;
        display:none;
        a{
            line-height:20px;
            color: @default-text-color;
            &:hover{
                text-decoration:underline;
                //color:#a1224d;
                color:#888;
            }
        }
        .pdfs{
            background: url("@{img-path}/pdf-icon-small.png") 0 50% no-repeat;
            padding-left:20px;
            a{
                &:nth-of-type(2){
                    &:before{
                        content:' | ';
                    }
                }
            }
        }
        .line-drawing{
            padding-left:20px;
            background: url("@{img-path}/line-drawing-icon.png") 0 50% no-repeat;
        }

    }
    .teaser {
        position: relative;
        //border-bottom: 1px solid @gray-base;
        padding: 20px 10px 10px;
        text-align:center;
        .hidden {
            display: none!important;
        }

        h3, h4 {
            margin: 0;
            width: 100%;
            height: 30px;
            line-height: 30px;
        }
        h3{
            height:auto;
        }

        > a {
        position: relative;

            &:hover {
                text-decoration: none;
            }
        }

        &.arr:after {
            border-left: 8px solid @gray-base;
            bottom: 37%;
            right: 20px;
        }

        .arr:after {
            border-left: 8px solid @gray-base;
            bottom: 37%;
            right: 20px;
        }

        .price {
            .dib;
            text-align: left;
            //width: 76%;
            padding: 5px;

            span {
                .dib;
                .font-size(24);
                color: @default-text-color;
                font-family: @font-family-base;
                font-weight: 400;
            }
        }

        form {
            position: absolute;
            top: 30px;
            //right: 5px;
            right:0;
        }

        button.add-to-wishlist {
            height: 12px;
            width: 14px;
            background: url("@{img-path}/grey-star.png") 0 0 no-repeat;
            //background: url("@{img-path}/HR-Star.svg") 0 0 no-repeat;
            border: none;
            cursor: pointer;

            &:hover {
                //background: url("@{img-path}/pink-star.png") 0 0 no-repeat;
                background: url("@{img-path}/HR-Star-Hover.svg") 0 0 no-repeat;

                // &:after {
                //     content: 'Add to wishlist';
                //     position: absolute;
                //     background: @white;
                //     padding: 15px;
                //     color:@gray-base;
                //     top: -30px;
                //     right: 20px;
                //     .font-size(12);
                //     width: 100px;
                //     border: 1px solid @hr-black;
                // }
            }
        }
    }

     > a,span {
        position: relative;

        &:hover {
            text-decoration: none;
        }
    }

    > a {
        border: 1px solid lighten(@gray-base, 50%);
    }

    img {
        width: 100%;
        max-height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }

    p {
        display: none;
    }
}

.product.box {
    > a {
        height: 202px;

    }

    .teaser {
        .arr:after {
            border-left: 8px solid @gray-base;
            position: static;
            margin-left: 10px;
        }
    }

    h3,
    h4 {
        height: 60px;
    }

    > span {
        height: 202px;
    }

    .cta {
        line-height: 30px;
        padding:0 1rem;
        overflow: hidden;
    }

    img {
        width: auto;
        max-width: 100%;
    }
}

.products {
    .level.box {
        .arr:after {
            position: absolute;
        }
    }
}

.level.box {
    position: static;

    .arr:after {
        position: static;
        margin-left: 10px;
    }

/*     .cta {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    } */

    h3 {
        margin-bottom: 10px;
    }

    p {
        display: block;
    }

    > a {
        // height: 200px;
        img {
            position: static;
        }
    }
}

.page {
    display: flex;
    flex-wrap: wrap;

    .level.box {
        display: flex;
        flex-direction: column;

        > a {
            flex-shrink: 0;
        }

        .teaser {
            height: 100%;
            min-height:125px;
            text-align:center;
            &:not(.no-short-text){
                //min-height: 155px;
                min-height:125px;                
            }
            h3,h4{
               // text-transform:uppercase;
            }
            
            a {
                width: 100%;
                
                p {
                    margin: 0;
                    line-height: 16px;
                    .font-size(12);
                    //text-decoration:underline;
                }
            }
        }
    }
}


// .area.alt-1,
// .area.alt-2 {
//     .level.box {

//         > a { 
//             width: 100%;
//             padding: 0 0 100% 0;
//             height: 0;

//             img {
//                 width: auto;height: auto;max-width: 100%;max-height: 100%;position: absolute!important;
//             }
//         }
//     }
// }


.wishlist {

    &:after {
    background: #c3b8ca;// url("@{img-path}/wishlist-stars.png");
    background-repeat: no-repeat;
    height: 12px;
    width: 14px;
    content: '';
    display: inline-block;
    position: absolute;
    right: 8px;
    top: 8px;

    }

    &:hover:after {
        background-position: 0 -12px;
    }
}


// Sharing Buttons

span.at-icon-wrapper {
    display: none!important;
}

.sharingButtons {
    text-align: center;
    padding: 10px 0;

    span {
        height: 60px;
        width: 60px;
        .dib;
        margin: 0 3px;
        cursor: pointer;
        .transition(@timing: 0.1s);
        opacity: 0.5;
        background-size: cover!important;

        &:hover {
            opacity: 1;
        }
    }
}

.st_facebook_custom {
    background: url('@{img-path}/social_media-facebook.png') no-repeat!important;
}

.st_twitter_custom {
    background: url('@{img-path}/social_media-twitter.png') no-repeat!important;
}

.st_googleplus_custom {
    background: url('@{img-path}/social_media-google.png') no-repeat!important;
}

.st_pinterest_custom {
    background: url('@{img-path}/social_media-pin_interest.png') no-repeat!important;
}

@media only screen and (max-width: @default-mobile-sm) {
    .sharingButtons span {
        width: 50px;
        height: 50px;
    }
}
