.wishlist-header {
	//background: url("@{img-path}/wishlist-red.png") 0 50% no-repeat;
	//background: url("@{img-path}/HR-Star.svg") 0 50% no-repeat;
    //padding-left:5rem;
	//margin-left: 30px;
	position: relative;
  //   text-indent: 40px;
	// &:before{
	// 	content: "";
	// 	background: url(/images/c/theme/hudson_reed/ui/HR-Star.svg) 0 0 no-repeat;
	// 	display: inline-block;
	// 	width: 40px;
	// 	height: 40px;
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// }
}

.wishlist-content {
	//padding: 20px 30px;
	padding:2rem 0;
	> p {
		.font-size(14);
	}
}

.wishlist-item {
	padding: 30px;
	border-bottom: 1px solid lighten(@gray-base, 40%);

	> div { 
		.element-fixer; 
	} 
 
	.image { 
		width: 20%;
        margin: 0 1% 10px 0; 
        img{
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
	}

	.wishlist-data {
		width: 79%;
		height: 147px;
		position: relative;
	}

	h3 {
		color: @default-text-color;
		.dib;
		width: 60%;
	}

	.price {		
		.dib;
		color: @default-text-color;

		span {
			.dib;
		}

		span {
			.font-size(22);
		}
	}

	form {
		position: absolute;
		bottom: 0;
	    left: 0;
	    right: 0;
	    display: block;
	    
		.quantity {
		    border: 0;
		    height: 25px;
		    padding: 0 10px;
		    width: 35px;
		    text-align: center;
		    background: lighten(@brand-blue, 40%);
		    margin: 0 5px;
		    vertical-align: super;
		}

		.btn-wrap {
			width: 60%;
			.dib;
		}
			
		span {
			.dib;
			cursor: pointer;
			line-height: 20px;
			.font-size(24);
			vertical-align: sub;
		}

		.remove {
		    background: 0;
		    color: #585858;
		    border: 0;
		    .font-size(12);
		    padding: 0;
		    margin: 0;
		    cursor: pointer;
		}
	}
}

.wishlist-total {
	//padding: 30px 20px;
	padding:3rem 0;
	.total {
		.element-fixer;
		width: 50%;
	    background: #F0F0F0;
	    color: @default-text-color;
	    .font-size(22);
	    text-align: right;
	    line-height: 40px;
	    padding-right: 15px;
	    font-weight: 600;

	    span {
	    	.dib;
	    	.font-size(22);
	    }
	}



	ul {
		.element-fixer;
		width: 50%;
        min-height: 132px;
		text-align: right;
    position: relative;
		li {
      position: absolute;
      right: 0;
      &:not(:first-of-type){
        bottom: 0;
      }
			a {
			// 	text-decoration: none;
			// 	width: 280px;
			// 	text-align: left;
			// 	text-transform: uppercase;
			// 	height: 56px;
			// 	.font-size(16);
			// 	border-bottom: 2px solid darken(@hr-red, 10%);
				margin-bottom: 10px;

			// 	&:hover {
			// 		background: darken(@hr-red, 5%);
			// 	}
			}
		}
	}
}

@media only screen and (max-width: @default-tablet-lg) {
	.wishlist-item {
		padding: 15px;

		.image {
			margin-bottom: 20px;	
		}

		> div {
			display: block;	
			width: 100%!important;	
		}
	}
}

@media only screen and (max-width: @default-mobile-lg) {
	.wishlist-total {
		>div, ul, ul li, ul li a {
			display: block!important;
			width: 100%!important;
			float: none;
		}

		>div, ul li:first-child {
			margin-bottom: 20px;	
		}
	}

	.wishlist-item {
		h3, 
		.btn-wrap {
			display: block;
			width: 100%;
			margin-bottom: 20px;
		}

		form {
			position: static;
		}

		.wishlist-data {
			height: auto;
		}

		.price {
			margin-bottom: 10px;
		}
	} 
}