.range-banner {
    position: relative;

    #range-box {  
        width: auto;
        display: none;
        padding: 20px;
        min-height: 0;
        position: absolute;
        left: 0;
        bottom: 0px;
        max-width: 300px;
        height: auto;
        background: rgba(67,35,93,.9);


        h1 {
            color: @white!important;
            .dib;
            margin: 0;
        }
    }
}

.children.products {
    height: auto;
    margin-bottom: 62px;
    overflow: visible;
} 

.range-content {
    margin-bottom: -97px;
}


@media only screen and (max-width: @default-tablet-lg) {
    .children.products {
        //height: 977px;
    }

    .top #range-box {
        padding: 10px;
    }

    .range-content {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: @default-mobile-lg) {
    .children.products {
        //height: 1278px;
    }

    .range-banner #range-box {
        min-width: 190px;
        h1 {
            .font-size(22);
        }
    }
}

@media only screen and (max-width: @default-mobile-lg) {
    .range-banner #range-box {
        min-width: 170px;
        padding: 5px;
    }
}
