.static {
    .contact-us {
        background: @off-gray;
        padding: 20px;
        .font-size(16);

        h3 {
            color: @default-text-color;
        }

        p {
            a {
                .dib;
                color: @default-text-color;
                text-decoration: underline;
            }
        }
    }
}