.product-page-wishlist-form {
  button {
    background: transparent;
    border: none;
    position: relative;
    height: 30px;
    width: 150px;
    font-size: 2rem;
    outline: none;
    margin-bottom: 1rem;
    cursor: pointer;
    span {
      color: @default-text-color;
    }
    &:hover {
      text-decoration: underline;
    }
    &:before {
      position: absolute;
      top: 5px;
      left:0;
    }
  }
}

.product-details-section{
    display:flex;
    margin:0 0 4rem;
    .product-image{
      .selected-image.zoomer {
        position: relative;
        .made-in-britain {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      display:inline-block;
      width:62.5%;
      vertical-align:top;
      flex:1.25;
      .selected-image{
          min-height:366px;
          line-height:366px;
          text-align: center;
          img{
              max-width:100%;
              vertical-align:middle;
          }
      }
      .alternative-images{
          margin:2rem 0;
          overflow: hidden;
          position: relative;
          padding:0 5rem;
          &:after,&:before{
              content: "";
              position: absolute;
              z-index: 1;                
              top:0;
              pointer-events: none;
              height:100%;
          }
          &:after{
              right: 0;
              background-image: linear-gradient(to right, 
              rgba(255,255,255, 0), 
              rgba(255,255,255, 1) 50%);
              width:15rem;
          }
          &:before{               
              left: 0;
              background:#fff;
              width:5rem;
          }
          .alternative-arrow{
              position: absolute;
              height:100%;
              z-index:2;
              top:0;
              &.alternative-left{
                  left:0;
              }
              &.alternative-right{
                  right:0;
              }
          }
          ul{
              width:300%;
              position: relative;
              transition-duration:1500ms;
              li{
                  display:inline-block;
                  width:10rem;
                  text-align:center;
                  &:not(:last-of-type){
                      margin:0 .5rem 0 0;
                  }
                  a{
                      line-height:75px;
                      img{
                          max-width:100%;
                          vertical-align: middle;
                      }
                  }
              }
          }
      }
  }
}
@media only screen and (max-width: @default-tablet-lg) {
    .product-details-section{
        flex-direction:column;
        display:block;
        .product-image{
            width:100%;
            .selected-image{
                text-align: center;
            }
        }
    }
}